import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.scss";

//componentes
import Header from "./componentes/estructura/Header.js";
import Inicio from "./componentes/paginas/Inicio.js";
import Alert from "./componentes/estructura/Alert.js";
import Login from "./componentes/estructura/Login";
import Admin from "./componentes/paginas/Admin.js";
import SingleProducto from "./componentes/productos/Singleproducto.js";
import Singleusuario from "./componentes/usuarios/Singleusuario.js";
import Registro from "./componentes/estructura/Registro";
import Inventario from "./componentes/paginas/Inventario.js";
import Cuentas from "./componentes/paginas/Cuentas";
//states
import ProdState from "./contexto/producto/ProdState.js";
import AlertState from "./contexto/alerta/AlertState.js";
import AutState from "./contexto/autentificacion/AutState.js";
import UserState from "./contexto/usuario/UserState.js";
import InvState from "./contexto/inventario/InvState.js";
import CuentaState from "./contexto/cuentas/CuentaState";
import ClienteState from "./contexto/cliente/ClienteState";

function App() {
	return (
		<AutState>
			<ClienteState>
				<AlertState>
					<ProdState>
						<CuentaState>
							<InvState>
								<UserState>
									<Router>
										<div className='App'>
											<Header />
											<div className='contenedor main'>
												<Alert />
												<Switch>
													<Route exact path='/' component={Inicio} />
													<Route exact path='/login' component={Login} />
													<Route exact path='/admin' component={Admin} />
													<Route exact path='/registro' component={Registro} />
													<Route exact path='/inventario' component={Inventario} />
													<Route exact path='/cuentas' component={Cuentas} />
													<Route exact path='/productos/:id' component={SingleProducto} />
													<Route exact path='/usuarios/:id' component={Singleusuario} />
												</Switch>
											</div>
										</div>
									</Router>
								</UserState>
							</InvState>
						</CuentaState>
					</ProdState>
				</AlertState>
			</ClienteState>
		</AutState>
	);
}

export default App;
