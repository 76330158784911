import React, { useContext, useEffect, useState, useRef } from "react";
import CuentaContext from "../../contexto/cuentas/cuentaContext.js";
import AlertContext from "../../contexto/alerta/alertContext.js";

const AbonosSingle = React.forwardRef((props, ref) => {
	const cuentaContext = useContext(CuentaContext);
	const alertContext = useContext(AlertContext);
	const { setAlert } = alertContext;
	const { cancelarAbono } = cuentaContext;
	/////////////////////////////////////////////////
	/// num to letras
	function Unidades(num) {
		switch (num) {
			case 1:
				return "un";
			case 2:
				return "dos";
			case 3:
				return "tres";
			case 4:
				return "cuatro";
			case 5:
				return "cinco";
			case 6:
				return "seis";
			case 7:
				return "siete";
			case 8:
				return "ocho";
			case 9:
				return "nueve";
		}

		return "";
	} //Unidades()

	function Decenas(num) {
		var decena = Math.floor(num / 10);
		var unidad = num - decena * 10;

		switch (decena) {
			case 1:
				switch (unidad) {
					case 0:
						return "diez";
					case 1:
						return "once";
					case 2:
						return "doce";
					case 3:
						return "trece";
					case 4:
						return "catorce";
					case 5:
						return "quince";
					default:
						return "dieci" + Unidades(unidad);
				}
			case 2:
				switch (unidad) {
					case 0:
						return "veinte";
					default:
						return "venti" + Unidades(unidad);
				}
			case 3:
				return DecenasY("treinta", unidad);
			case 4:
				return DecenasY("cuarenta", unidad);
			case 5:
				return DecenasY("cincuenta", unidad);
			case 6:
				return DecenasY("sesenta", unidad);
			case 7:
				return DecenasY("setenta", unidad);
			case 8:
				return DecenasY("ochenta", unidad);
			case 9:
				return DecenasY("noventa", unidad);
			case 0:
				return Unidades(unidad);
		}
	} //Unidades()

	function DecenasY(strSin, numUnidades) {
		if (numUnidades > 0) return strSin + " y " + Unidades(numUnidades);

		return strSin;
	} //DecenasY()

	function Centenas(num) {
		var centenas = Math.floor(num / 100);
		var decenas = num - centenas * 100;

		switch (centenas) {
			case 1:
				if (decenas > 0) return "ciento " + Decenas(decenas);
				return "cien";
			case 2:
				return "doscientos " + Decenas(decenas);
			case 3:
				return "trescientos " + Decenas(decenas);
			case 4:
				return "cuatrocientos " + Decenas(decenas);
			case 5:
				return "quinientos " + Decenas(decenas);
			case 6:
				return "seicientos " + Decenas(decenas);
			case 7:
				return "setecientos " + Decenas(decenas);
			case 8:
				return "ochocientos " + Decenas(decenas);
			case 9:
				return "novecientos " + Decenas(decenas);
		}

		return Decenas(decenas);
	} //Centenas()

	function Seccion(num, divisor, strSingular, strPlural) {
		var cientos = Math.floor(num / divisor);
		var resto = num - cientos * divisor;

		var letras = "";

		if (cientos > 0)
			if (cientos > 1) letras = Centenas(cientos) + " " + strPlural;
			else letras = strSingular;

		if (resto > 0) letras += "";

		return letras;
	} //Seccion()

	function Miles(num) {
		var divisor = 1000;
		var cientos = Math.floor(num / divisor);
		var resto = num - cientos * divisor;

		var strMiles = Seccion(num, divisor, "un mil", "mil");
		var strCentenas = Centenas(resto);

		if (strMiles == "") return strCentenas;

		return strMiles + " " + strCentenas;
	} //Miles()

	function Millones(num) {
		var divisor = 1000000;
		var cientos = Math.floor(num / divisor);
		var resto = num - cientos * divisor;

		var strMillones = Seccion(num, divisor, "un millon de", "millones de");
		var strMiles = Miles(resto);

		if (strMillones == "") return strMiles;

		return strMillones + " " + strMiles;
	} //Millones()

	function NumeroALetras(num) {
		var data = {
			numero: num,
			enteros: Math.floor(num),
			centavos: Math.round(num * 100) - Math.floor(num) * 100,
			letrasCentavos: "",
			letrasMonedaPlural: "Pesos", //"PESOS", 'Dólares', 'Bolívares', 'etcs'
			letrasMonedaSingular: "Peso", //"PESO", 'Dólar', 'Bolivar', 'etc'

			letrasMonedaCentavoPlural: "centavos",
			letrasMonedaCentavoSingular: "centavos",
		};

		if (data.centavos > 0) {
			data.letrasCentavos =
				"con " +
				(function () {
					if (data.centavos == 1)
						return Millones(data.centavos) + " " + data.letrasMonedaCentavoSingular;
					else return Millones(data.centavos) + " " + data.letrasMonedaCentavoPlural;
				})();
		}

		if (data.enteros == 0)
			return "cero " + data.letrasMonedaPlural + " " + data.letrasCentavos;
		if (data.enteros == 1)
			return (
				Millones(data.enteros) +
				" " +
				data.letrasMonedaSingular +
				" " +
				data.letrasCentavos
			);
		else
			return (
				Millones(data.enteros) +
				" " +
				data.letrasMonedaPlural +
				" " +
				data.letrasCentavos
			);
	} //NumeroALetras()
	//////////////////////////////////////////////////

	const {
		unsetCuenta,
		cuenta,
		crearAbono,
		traerAbonosByNota,
		abonosr,
		statusdCuenta,
		traerCuentas,
		traerCuentasPagadas,
	} = cuentaContext;
	const {
		folio,
		cliente,
		fecha,
		monto,
		tel,
		refe,
		mpago,
		dir,
		conceptos,
		status,
		abonos,
		sucursal,
		vendedor,
	} = cuenta;

	let abo = 0;
	if (abonosr.length > 0) {
		abonosr.forEach((row) => {
			if (row["status"] === 1) {
				abo = abo + parseFloat(row["monto"]);
			}
		});
	}
	const [abono, setAbono] = useState(0);
	const [tipo, setTipo] = useState("Efectivo");
	const [suc, setSuc] = useState("");
	const onChangeAbono = (e) => setAbono(e.target.value);
	let estatus =
		status === "pendiete"
			? "bg-danger"
			: status === "pagada"
			? "bg-success"
			: "bg-light";
	let ffecha = "";
	if (typeof fecha !== "undefined") {
		const dateRaw = fecha.substring(0, 10);
		const fechaArr = dateRaw.split("-");
		const meses = [
			"dud",
			"Enero",
			"Feb",
			"Mar",
			"Abril",
			"Mayo",
			"Jun",
			"Jul",
			"Ago",
			"Sep",
			"Oct",
			"Nov",
			"Dic",
		];
		ffecha =
			fechaArr[2] +
			"/" +
			meses[fechaArr[1].replace(/^0+/, "")] +
			"/" +
			fechaArr[0];
	}
	let conceptosArr = [];
	if (typeof conceptos !== "undefined") {
		conceptosArr = conceptos;
	}

	const onChangeTipo = (e) => {
		setTipo(e.target.value);
	};
	const onChangeSuc = (e) => {
		setSuc(e.target.value);
	};

	const pushAbono = async () => {
		if ((typeof folio !== "undefined" || folio !== null) && abono !== 0) {
			if (abono <= monto - abo) {
				const resp = await crearAbono(folio, abono, tipo, suc);
				if (resp.success) {
					setAbono(0);
					setAlert("Abono agregado con éxito", "success");
					traerAbonosByNota(folio);
				}
			} else {
				alert("no puede realizar el abono por que es mayor que el saldo");
			}
		} else {
			setAlert(
				"No se puede ingresar el abono seleccione una nota o agregue un monto",
				"danger"
			);
		}
	};
	const liquidar = async () => {
		if (monto - abo === 0) {
			let body = {
				cuentaid: folio,
				status: "pagada",
			};
			const resp = await statusdCuenta(body);
			console.log(resp);
			traerCuentasPagadas();
			alert("liquidada");
		} else {
			alert("no puede liquidar la nota mientras tenga saldo pendiente");
		}
	};
	const killAbono = async (id) => {
		let sec = window.confirm("desea cancelar el abono?");
		let resp = null;
		if (sec) {
			resp = await cancelarAbono(id);
			if (resp.data.success) {
				setAlert("abono cancelado", "success");
				setAlert("fallo la cancelación del abono");
			} else {
				setAlert("operacion Cancelada", "danger");
			}
		}
	};
	const entregada = async () => {
		if (monto - abo === 0) {
			let body = {
				cuentaid: folio,
				status: "entregada",
			};
			const resp = await statusdCuenta(body);
			console.log(resp);
			traerCuentasPagadas();
			alert("Estatus cambiado a entregada");
		} else {
			alert(
				"no puede marcar como entregada mientras la nota tenga saldo pendiente"
			);
		}
	};
	useEffect(() => {
		if (typeof folio !== "undefined" && abonosr.length === 0) {
			//traerAbonosByNota(folio);
		}
		setSuc(sucursal);
	}, [cuenta, abonosr]);

	const preletras = NumeroALetras(monto);
	var letras = preletras.charAt(0).toUpperCase() + preletras.slice(1);
	const presaldo = NumeroALetras(monto - abo);
	var saldoletras = presaldo.charAt(0).toUpperCase() + presaldo.slice(1);

	return (
		<div ref={ref} className='p-1'>
			<h2 className='gspan-2 my'>Abonos</h2>

			<div className='grid-4 gridgapmini noprint'>
				<h3 className='gspan-4 m0'>Añadir abono</h3>
				<select name='tipo' id='' value={tipo} onChange={onChangeTipo}>
					<option value='Efectivo'>Efectivo</option>
					<option value='TCredito'>TCredito</option>
					<option value='TDebito'>TDebito</option>
					<option value='Transferencia'>Transferencia</option>
				</select>
				<select name='tipo' id='' value={suc} onChange={onChangeSuc}>
					<option value='salida'>Salida</option>
					<option value='centro'>Centro</option>
					<option value='arriaga'>Arriaga</option>
				</select>
				<input
					type='number'
					className='m0'
					value={abono}
					onChange={onChangeAbono}
				/>
				<button className='btn btn-block m0 noprint' onClick={pushAbono}>
					Enviar
				</button>
			</div>
			<table className='my nota w-100'>
				<tbody>
					<tr>
						<td colSpan={2}>
							<h3>{cliente}</h3>
						</td>
						<td colSpan={2}>
							<h3>Folio:{folio}</h3>
						</td>
					</tr>
					<tr>
						<th>Fecha</th>
						<th>Pagos</th>
						<th>Tipo</th>
						<th>Cnl</th>
					</tr>
					{typeof abonosr !== "undefined"
						? abonosr.map((row, i) => {
								{
									console.log(row);
								}
								return (
									<tr key={i}>
										<td className={row.status === 0 ? "cancelada" : null}>{row.fecha}</td>
										<td className={row.status === 0 ? "cancelada" : null}>
											${row.monto}
											<strong>{i === 0 ? " Enganche" : " Abono"}</strong>
										</td>
										<td className={row.status === 0 ? "cancelada" : null}>
											<strong>{row.tipo}</strong>
										</td>
										<td>
											<i
												onClick={() => killAbono(row.id)}
												className='fas fa-trash-alt lead'
											></i>
										</td>
									</tr>
								);
						  })
						: null}
				</tbody>
			</table>
			<h3 className='m0 gspan-3 lead'>Saldo: ${monto - abo}</h3>
			<p className='m0 gspan-3'>Saldo con letra: {saldoletras}</p>
		</div>
	);
});

export default AbonosSingle;
