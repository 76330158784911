import React, { useContext, useEffect } from "react";
import InvContext from "../../contexto/inventario/invContext.js";
import Spinner from "../estructura/Spinner.js";
import Elevistaregistro from "./Elevistaregistro.js";

const Vistaregistro = () => {
	const invContext = useContext(InvContext);
	const { prodReg, invLoading } = invContext;
	let registros = prodReg;

	useEffect(() => {
		let registros = prodReg;
		//eslint-disable-next-line
	}, [prodReg]);

	if (invLoading) {
		return <Spinner />;
	} else {
		return (
			<div>
				<h2>Productos a registrar</h2>
				<ul className='card'>
					{registros.map((reg) => (
						<Elevistaregistro key={reg.producto} reg={reg} />
					))}
				</ul>
			</div>
		);
	}
};

export default Vistaregistro;
