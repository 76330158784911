import React, { Fragment } from "react";
import Productos from "../productos/Productos.js";
import BusquedaDisp from "../productos/BusquedaDisp.js";
const Inicio = () => {
	return (
		<Fragment>
			<BusquedaDisp />
			<Productos />
		</Fragment>
	);
};

export default Inicio;
