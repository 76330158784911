import React, { useContext } from "react";
import CuentaContext from "../../contexto/cuentas/cuentaContext.js";
import PropTypes from "prop-types";

const Tdcuenta = ({ cuenta: { folio, cliente, fecha, monto, status } }) => {
	const cuentaContext = useContext(CuentaContext);
	const {
		setCuenta,
		cuentas,
		unsetAbonos,
		traerAbonosByNota,
		statusdCuenta,
		unsetCuentas,
	} = cuentaContext;
	const dateRaw = fecha.substring(0, 10);
	const fechaArr = dateRaw.split("-");
	const meses = [
		"dud",
		"Enero",
		"Feb",
		"Mar",
		"Abril",
		"Mayo",
		"Jun",
		"Jul",
		"Ago",
		"Sep",
		"Oct",
		"Nov",
		"Dic",
	];
	const ffecha =
		fechaArr[2] + "/" + meses[fechaArr[1].replace(/^0+/, "")] + "/" + fechaArr[0];
	const borrarCuenta = async () => {
		const conf = window.confirm("está seguro que Desea Cancelar la nota?");
		if (conf) {
			let body = {
				cuentaid: folio,
				status: "cancelada",
			};
			statusdCuenta(body);
			unsetCuentas();
		}
	};
	///////////////////////////
	// traer nota on click
	const oCtraerNota = async () => {
		const cuenta = cuentas.find((row) => row.folio === folio);
		if (!cuenta) {
			alert("no hay detalles de esa Cuenta");
		} else {
			unsetAbonos();
			setCuenta(cuenta);
			traerAbonosByNota(cuenta.folio);
		}
	};
	return (
		<tr className='tdlink' onClick={oCtraerNota}>
			<td className={status === "cancelada" ? "cancelada" : null}>{folio}</td>
			<td className={status === "cancelada" ? "cancelada" : null}>{cliente}</td>
			<td className={status === "cancelada" ? "cancelada" : null}>{ffecha}</td>
			<td className={status === "cancelada" ? "cancelada" : null}>{monto}</td>
			<td>
				{status !== "cancelada" ? (
					<i onClick={borrarCuenta} className='fas fa-trash-alt lead'></i>
				) : null}
			</td>
		</tr>
	);
};
Tdcuenta.propTypes = {
	cuenta: PropTypes.object.isRequired,
};
export default Tdcuenta;
