import React, { useState } from "react";
import Thusuarios from "../usuarios/Thusuarios.js";
import Registro from "../estructura/Registro.js";

const Aduser = () => {
	// nuevo troducto toggle

	const [newUsr, setNewUsr] = useState(false);

	const newUsrToggle = () => {
		newUsr ? setNewUsr(false) : setNewUsr(true);
	};
	return (
		<div className='AdminUser'>
			<h2>
				Usuarios<i className='fas fa-plus-square' onClick={newUsrToggle}></i>
			</h2>
			{newUsr ? <Registro /> : null}
			<Thusuarios />
		</div>
	);
};

export default Aduser;
