import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import AutContext from "../../contexto/autentificacion/autContext.js";
import logo from "../assets/logo.png";
const Header = () => {
	const autContext = useContext(AutContext);
	const { logout, isloged, logstatus } = autContext;
	const history = useHistory();
	//logout
	const desloguear = () => {
		logout();
		history.push("/");
	};

	useEffect(() => {
		if (!logstatus.loged) {
			isloged();
		}
		//eslint-disable-next-line
	}, []);

	return (
		<header className='App-header'>
			<div className='head grid-3'>
				<div className='gspan-2 text-left'>
					<Link className='mainlink' to='/'>
						<img
							src={logo}
							alt=''
							height='100px'
							width='128px'
							style={{ width: "auto !important" }}
						/>
						<div
							style={{
								display: "inline-block",
								marginLeft: "15px",
								verticalAlign: "top",
								paddingTop: "10px",
							}}
						>
							<h1 className='m0' style={{ lineHeight: "1em" }}>
								Mueblerias las 3 MMM
							</h1>
							<p className='m0'>Muebles Colchones y electrodomésticos</p>
						</div>
					</Link>
				</div>
				<div className='col text-right'>
					{logstatus.role ? (
						<>
							<Link to='/inventario'>
								<i className='fas fa-cubes large my'></i>
							</Link>
							<Link to='/admin'>
								<i className='fas fa-user-cog large my'></i>
							</Link>
							<Link to='/cuentas'>
								<i className='fas fa-receipt large my'></i>
							</Link>
						</>
					) : null}
					{logstatus.loged ? (
						<i className='fas fa-sign-out-alt  large my' onClick={desloguear}></i>
					) : (
						<Link to='/login'>
							<i className='fas fa-sign-in-alt large my'></i>
						</Link>
					)}
				</div>
			</div>
		</header>
	);
};

export default Header;
