import React, { useReducer } from "react";
import axios from "axios";
import ClienteContext from "./clienteContext.js";
import ClienteReducer from "./clienteReducer.js";
import { SET_LOADING, CLIENTES, CLIENTE } from "../types.js";

const ClienteState = (props) => {
	//init state
	const initialState = {
		clientes: [],
		cliente: {},
		clienteLoading: false,
	};
	//se declara dispatch
	const [state, dispatch] = useReducer(ClienteReducer, initialState);

	//traer access token
	const getaccessToken = async () => {
		return window.localStorage.getItem("access_token");
	};
	///setUserLoading
	const setClienteLoading = async () => {
		dispatch({ type: SET_LOADING });
	};
	//////////////////////////////////////////
	//3 crear usuario
	const nuevoCliente = async (Nombre, Dir, Tel) => {
		let ufeedB = null;
		setClienteLoading();
		const body = {
			nombre: Nombre,
			dir: Dir,
			tel: Tel,
		};
		try {
			const resp = await axios.post(`/api/clientes`, body, {
				headers: { "Content-Type": "application/json" },
			});
			if (resp.data.success) {
				ufeedB = {
					success: resp.data.success,
					mensaje: resp.data.messages[0],
					color: "success",
				};
				dispatch({ type: CLIENTE, payload: resp.data.data });
			}
		} catch (err) {
			ufeedB = {
				success: false,
				mensaje: err.response,
				color: "danger",
			};
		}
		return ufeedB;
	};
	//////////////////////////////////////////////////////
	//1 traer todos los usuarios
	const traerClientes = async () => {
		let ufeedB = null;
		const accessToken = await getaccessToken();
		setClienteLoading();
		try {
			const resp = await axios.get("/api/clientes", {
				headers: {
					Authorization: `${accessToken}`,
				},
			});
			if (resp.data.success) {
				ufeedB = {
					success: resp.data.success,
					color: "success",
					message: resp.data.messages[0],
				};
				dispatch({ type: CLIENTES, payload: resp.data.data.clientes });
			}
		} catch (err) {
			ufeedB = {
				success: false,
				mensaje: "No se encontraron Clientes " + err.response.data.messages[0],
				color: "danger",
			};
		}
		return ufeedB;
	};
	return (
		<ClienteContext.Provider
			value={{
				clientes: state.clientes,
				cliente: state.cliente,
				clienteLoading: state.clienteLoading,
				nuevoCliente,
				traerClientes,
			}}
		>
			{props.children}
		</ClienteContext.Provider>
	);
};

export default ClienteState;
