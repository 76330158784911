import React, { useContext, useEffect, useState, useRef } from "react";
import CuentaContext from "../../contexto/cuentas/cuentaContext.js";
import AlertContext from "../../contexto/alerta/alertContext.js";
import TdOreder from "./TdOreder.js";

const OrdenSingle = React.forwardRef((props, ref) => {
	const cuentaContext = useContext(CuentaContext);
	const alertContext = useContext(AlertContext);
	const { setAlert } = alertContext;
	const {
		unsetCuenta,
		cuenta,
		crearAbono,
		traerAbonosByNota,
		abonosr,
		statusdCuenta,
		traerCuentas,
		traerCuentasPagadas,
	} = cuentaContext;
	let fechai = "Sin fecha .";
	const {
		folio,
		cliente,
		fecha,
		monto,
		tel,
		refe,
		mpago,
		dir,
		conceptos,
		status,
		ciudad,
		abonos,
		sucursal,
		vendedor,
	} = cuenta;
	if (typeof fecha === "string") {
		fechai = fecha;
	}
	const apagar = monto - abonos;
	return (
		<div ref={ref} className='p-1'>
			<h2 className='small'>Orden de entrega</h2>
			<table className='nota w-100'>
				<tbody>
					<tr>
						<td>
							<h3 className='m0'>
								{typeof sucursal === "string"
									? sucursal.charAt(0).toUpperCase() + sucursal.slice(1)
									: "suc"}
							</h3>
						</td>
						<td>
							<h3 className='m0'>Fecha: {fechai.substr(0, 10)}</h3>
						</td>
						<td>
							<h3 className='m0'>Folio: {folio}</h3>
						</td>
					</tr>
					<tr>
						<td colSpan={3}>
							<p className='small m0'>A nombre de:</p>
							<h3 className='m0'>{cliente}</h3>
						</td>
					</tr>
					<tr>
						<td className='nota' colSpan={3}>
							{" "}
							<span
								className='small notahead'
								style={{ position: "absolute", top: "3px", left: "3px" }}
							>
								Dir:
							</span>
							{dir}
						</td>
					</tr>
					<tr>
						<td className='nota' colSpan={3}>
							{" "}
							<span
								className='small notahead'
								style={{ position: "absolute", top: "3px", left: "3px" }}
							>
								Ref.
							</span>
							{refe}
						</td>
					</tr>
					<tr>
						<td className='nota ' colSpan={2}>
							{" "}
							<span
								className='small notahead'
								style={{ position: "absolute", top: "3px", left: "3px" }}
							>
								Ciudad:
							</span>
							{ciudad}
						</td>
						<td className='nota'>
							{" "}
							<span
								className='small notahead'
								style={{ position: "absolute", top: "3px", left: "3px" }}
							>
								Tel:
							</span>
							{tel}
						</td>
					</tr>
				</tbody>
			</table>
			<table className='nota w-100'>
				<thead>
					<tr>
						<th colSpan={1}>Cant.</th>
						<th colSpan={4}>Producto.</th>
						<th colSpan={1} className='noprint'>
							ctrl.
						</th>
					</tr>
				</thead>
				<tbody>
					{typeof conceptos !== "undefined" &&
						conceptos.map((row, i) => {
							return <TdOreder key={i} cant={row.cantidad} concepto={row.concepto} />;
						})}
					{apagar > 0 && mpago !== "credito" ? (
						<tr>
							<td colSpan={6}>
								<p className='med m0'>Monto a pagar al entregar:</p>
								<h3 className='my-1 lead'>$ {apagar}</h3>
							</td>
						</tr>
					) : mpago === "credito" ? (
						<tr>
							<td colSpan={6}>
								<h3 className='m0 lead'>Crédito</h3>
							</td>
						</tr>
					) : (
						<tr>
							<td colSpan={6}>
								<h3 className='m0 lead'>Pagada</h3>
							</td>
						</tr>
					)}
				</tbody>
			</table>
		</div>
	);
});

export default OrdenSingle;
