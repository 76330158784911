import React, { useContext, useState } from "react";
import CuentaContext from "../../contexto/cuentas/cuentaContext.js";
import AlertContext from "../../contexto/alerta/alertContext.js";

const CuentaBusqeda = () => {
	////////////////////////////////
	//Contextos
	const cuentaContext = useContext(CuentaContext);
	const {
		cuentaBusqByNom,
		cuentaBusqById,
		traerCuentas,
		traerCuentasPagadas,
		traerCuentasApartadas,
		traerCuentasCreditos,
		traerCuentasEntregadas,
		nullCuentas,
		cuentaBusqByCit,
	} = cuentaContext;
	const alertContext = useContext(AlertContext);
	const { setAlert } = alertContext;
	const [nombreBusq, setNombreBusq] = useState("");
	const [cityBusq, setCityBusq] = useState("");
	const [idBusq, setIdBusq] = useState("");

	//onchanges
	const onChangeNom = (e) => {
		setNombreBusq(e.target.value);
		setIdBusq("");
		setCityBusq("");
	};
	const onChangeCit = (e) => {
		setCityBusq(e.target.value);
		setIdBusq("");
		setNombreBusq("");
	};
	const onChangeId = (e) => {
		setIdBusq(e.target.value);
		setNombreBusq("");
		setCityBusq("");
	};

	const onSubBusqNom = async (e) => {
		e.preventDefault();
		const resp = await cuentaBusqByNom(nombreBusq);
		console.log(resp);

		if (resp.data.success) {
			setAlert("cuentas encontradas", "success");
		} else {
			setAlert("no se encontraron cuentas", "danger");
			traerCuentas();
		}
	};
	const onSubBusqCit = async (e) => {
		e.preventDefault();
		const resp = await cuentaBusqByCit(cityBusq);
		if (resp.data.success) {
			setAlert("cuentas encontradas", "success");
		} else {
			setAlert("no se encontraron cuentas", "danger");
			traerCuentas();
		}
	};
	const onSubBusqId = async (e) => {
		e.preventDefault();
		console.log(e.target.value);

		const resp = await cuentaBusqById(idBusq);
		console.log(resp);

		if (resp) {
			setAlert("cuenta encontradas", "success");
		} else {
			setAlert("no se encontraron cuentas", "danger");
			traerCuentas();
		}
	};
	const traePendientes = () => {
		nullCuentas();
		traerCuentas();
	};
	const traerPagadas = () => {
		nullCuentas();
		traerCuentasPagadas();
	};
	const traerEntregadas = () => {
		nullCuentas();
		traerCuentasEntregadas();
	};
	const traerApartadas = () => {
		nullCuentas();
		traerCuentasApartadas();
	};
	const traerCreditos = () => {
		nullCuentas();
		traerCuentasCreditos();
	};
	return (
		<div className=''>
			<h2 className='my'>Buscar Notas</h2>
			<form className='m0 flex-sb' onSubmit={onSubBusqNom}>
				<input
					type='text'
					name='nombreBusq'
					placeholder='Buscar por Nombre'
					value={nombreBusq}
					onChange={onChangeNom}
					className='m0'
					style={{ width: "70%" }}
				/>
				<input
					type='submit'
					name='Buscar'
					className='btn btn-block my'
					value='Buscar'
					style={{ width: "25%", margin: "0 0.5rem" }}
				/>
			</form>
			<form className='m0 flex-sb' onSubmit={onSubBusqId}>
				<input
					type='number'
					name='idBusq'
					placeholder='Buscar folio'
					value={idBusq}
					onChange={onChangeId}
					className='m0'
					style={{ width: "70%" }}
				/>
				<input
					type='submit'
					name='Buscar'
					className='btn btn-block my'
					value='Buscar'
					style={{ width: "25%", margin: "0 0.5rem" }}
				/>
			</form>
			<form className='m0 flex-sb' onSubmit={onSubBusqCit}>
				<input
					type='text'
					name='citBusq'
					placeholder='Buscar por Ciudad'
					value={cityBusq}
					onChange={onChangeCit}
					className='m0'
					style={{ width: "70%" }}
				/>
				<input
					type='submit'
					name='Buscar'
					className='btn btn-block my'
					value='Buscar'
					style={{ width: "25%", margin: "0 0.5rem" }}
				/>
			</form>
			<div className='my flex-sb flex-g'>
				<button className='btn btn-dark' onClick={() => traePendientes()}>
					Pendientes
				</button>
				<button className='btn btn-dark' onClick={() => traerPagadas()}>
					Pagadas
				</button>
				<button className='btn btn-dark' onClick={() => traerEntregadas()}>
					Entregadas
				</button>
				<button className='btn btn-dark' onClick={() => traerApartadas()}>
					Apartadas
				</button>
				<button className='btn btn-dark' onClick={() => traerCreditos()}>
					Creditos
				</button>
			</div>
		</div>
	);
};

export default CuentaBusqeda;
