import React, { useContext, useEffect, useState } from "react";
import AutContext from "../../contexto/autentificacion/autContext.js";
import UserContext from "../../contexto/usuario/userContext.js";
import Spinner from "../estructura/Spinner.js";
import Patchusuario from "./Patchusuario.js";

const Singleusuario = ({ match }) => {
	const userContext = useContext(UserContext);
	const { traerUsuario, usuario, userLoading } = userContext;
	const autContext = useContext(AutContext);
	const { refreshSession } = autContext;
	const [updateU, setUpdateU] = useState(false);
	const id = match.params.id;

	const updateTogg = () => {
		updateU ? setUpdateU(false) : setUpdateU(true);
	};
	///////////////////////
	//Use Effect
	useEffect(() => {
		const init = async () => {
			await refreshSession();
			traerUsuario(id);
		};
		init();
		//eslint-disable-next-line
	}, []);

	if (userLoading) {
		return <Spinner />;
	} else {
		return (
			<div className='SinProd card'>
				<div className='text-right'>
					<i className='fas fa-edit lead m0 floatR' onClick={updateTogg}></i>
				</div>
				{updateU ? <Patchusuario tog={updateTogg} /> : null}
				<h1 className='m0'>
					{usuario.username}
					<strong className='small my lightcolor'> {usuario.role}</strong>
				</h1>
				<strong className='small my lightcolor'>Nombre Completo: </strong>
				<h2 className='m0'>{usuario.fullname}</h2>
				<strong className='small my lightcolor'>Correo: </strong>
				<h2 className='m0'>{usuario.mail}</h2>
				<strong className='small my lightcolor'>Teléfono: </strong>
				<h2 className='m0'>{usuario.tel}</h2>
			</div>
		);
	}
};

export default Singleusuario;
