import React, { useContext, useEffect, useState } from "react";
import ProdContext from "../../contexto/producto/prodContext";
import Patchproducto from "./Patchproducto.js";
import Spinner from "../estructura/Spinner.js";

const Singleproducto = ({ match }) => {
	const prodContext = useContext(ProdContext);
	const { traerProducto, producto, loading } = prodContext;
	const [updateP, setUpdateP] = useState(false);
	const id = match.params.id;
	const role = localStorage.getItem("role");
	const updateTogg = () => {
		updateP ? setUpdateP(false) : setUpdateP(true);
	};
	///////////////////////
	//Use Effect
	useEffect(() => {
		traerProducto(id);
		//eslint-disable-next-line
	}, []);
	if (loading) {
		return <Spinner />;
	} else {
		return (
			<div>
				<div className='SinProd card'>
					<div className='text-right'>
						{role === "Admin" && (
							<i className='fas fa-edit lead m0' onClick={updateTogg}></i>
						)}
					</div>
					{updateP && role === "Admin" ? <Patchproducto tog={updateTogg} /> : null}
					<div className=''>
						<strong className='small my lightcolor'>Producto: </strong>
						<h2 className='m0'>{producto.Nombre}</h2>
					</div>
					<div>
						<strong className='small my lightcolor'>Categoría: </strong>
						<h2 className='m0'>{producto.Categoria}</h2>
					</div>
					<div className='SingleDesc'>
						<strong className='small my lightcolor'>Descripción:</strong>
						<p>{producto.Descripcion}</p>
					</div>
					<div className=''>
						<img src={producto.Imagen} alt={producto.Descripcion} />
					</div>
				</div>
			</div>
		);
	}
};

export default Singleproducto;
