import React, { useEffect, useContext, useState } from "react";
import ProdContext from "../../contexto/producto/prodContext.js";
import AutContext from "../../contexto/autentificacion/autContext.js";
import AlertContext from "../../contexto/alerta/alertContext.js";

const Newproducto = () => {
	const prodContext = useContext(ProdContext);
	const autContext = useContext(AutContext);
	const alertContext = useContext(AlertContext);
	const { categorias, traerCategorias, crearProducto, traerProductos } =
		prodContext;
	const { refreshSession } = autContext;
	const { setAlert } = alertContext;

	////////////////////////////////////////////
	// state
	const [nombre, setNombre] = useState("");
	const [categoria_id, setCat] = useState(0);
	const [precio, setPrecio] = useState("");
	const [descripcion, setDescription] = useState("");
	const [imagen, setImagen] = useState("");

	///////////////////////////////////////////
	//onchange de imputs
	const onChangeNombre = (e) => setNombre(e.target.value);
	const onChangeCat = (e) => setCat(e.target.value);
	const onChangePrecio = (e) => setPrecio(e.target.value);
	const onChangeDescription = (e) => setDescription(e.target.value);
	const onChangeImagen = (e) => setImagen(e.target.files[0]);

	///////////////////////////////////////////
	// nuevo on submit
	const newProducto = async (ev) => {
		ev.preventDefault();
		await refreshSession();
		const resp = await crearProducto(
			nombre,
			categoria_id,
			precio,
			descripcion,
			imagen
		);
		console.log(resp);
		if (resp) {
			await traerProductos();
			setAlert("producto creado", "success");
		} else {
			setAlert("No se pudo crear el producto", "danger");
		}
	};

	useEffect(() => {
		if (categorias.length === 0) {
			traerCategorias();
		}
		//eslint-disable-next-line
	}, [categorias]);

	return (
		<div>
			<form id='npform' onSubmit={newProducto} className='grid-2 smallgap'>
				<div className='gspan-2'>
					<label htmlFor='nombre'>Nombre del Producto</label>
					<input
						type='text'
						name='nombre'
						placeholder='Ingrese el nombre del producto'
						value={nombre}
						onChange={onChangeNombre}
						min='0'
						max='9999'
						className='bigimput my'
					/>
				</div>
				<div className=''>
					<label htmlFor='categoria_id'>categoría</label>
					<select onChange={onChangeCat} className='my bigimput'>
						<option value='0'>--categoría--</option>
						{categorias.map((cate) => (
							<option key={cate.id} value={cate.id}>
								{cate.Nombre}
							</option>
						))}
					</select>
				</div>
				<div className=''>
					<label htmlFor='precio'>Precio</label>
					<input
						type='number'
						step='0.01'
						name='precio'
						placeholder='Precio'
						value={precio}
						onChange={onChangePrecio}
						min='0'
						max='999999'
						className='my'
					/>
				</div>
				<div className='gspan-2'>
					<label htmlFor='descripcion'>Descripción</label>
					<textarea
						name='descripcion'
						placeholder='Ingrese la descripción del producto'
						value={descripcion}
						onChange={onChangeDescription}
						min='0'
						max='999'
						className='bigimput my'
					/>
				</div>
				<div className='gspan-2'>
					<label htmlFor='image'>Imagen</label>
					<input
						type='file'
						name='imagen'
						placeholder='Ingrese la descripción del producto'
						onChange={onChangeImagen}
						className='btn my'
					/>
				</div>
				<div className='gspan-2'>
					<input
						type='submit'
						className='btn btn-dark btn-block my'
						value='Crear Producto'
					/>
				</div>
			</form>
		</div>
	);
};

export default Newproducto;
