import React, { useReducer } from "react";
import axios from "axios";
import InvContext from "./invContext.js";
import InvReducer from "./invReducer.js";
import {
	INVENTARIO,
	SET_LOADING,
	MOVIMIENTOS,
	SET_MOVLOADING,
	INVPRODREG,
	CLEARSTATE,
} from "../types.js";

const InvState = (props) => {
	/////////////////////////////
	//se inicializa de state
	const initialState = {
		inventario: [],
		invloading: false,
		movimientos: [],
		movLoading: false,
		invBody: {},
		prodReg: [],
	};

	/////////////////////////////
	//traer access token
	const getaccessToken = async () => {
		return window.localStorage.getItem("access_token");
	};

	/////////////////////////////
	//se declara el dispatch
	const [state, dispatch] = useReducer(InvReducer, initialState);

	//set loading
	const setLoading = () => dispatch({ type: SET_LOADING });
	const setMovLoading = () => dispatch({ type: SET_MOVLOADING });
	//ser productos registro
	const setprodReg = (passprodReg) => {
		console.log(state.prodReg);

		dispatch({ type: INVPRODREG, payload: passprodReg });
	};
	///////////////////////////
	// Inventario completo
	const traerInventario = async () => {
		let accessToken = await getaccessToken();
		return new Promise((resolve, reject) => {
			setLoading();
			axios
				.get("/api/inventario", {
					headers: {
						Authorization: `${accessToken}`,
					},
				})
				.then((resp) => {
					dispatch({ type: INVENTARIO, payload: resp.data.data });
					console.log(resp.data);
					resolve(true);
				})
				.catch((err) => {
					console.log(err.response);
					reject(false);
				});
		});
	};

	/////////////////////////////
	// traer movimientos
	const traerMovimientos = async () => {
		let accessToken = await getaccessToken();
		return new Promise((resolve, reject) => {
			setMovLoading();
			axios
				.get("/api/movimientos", {
					headers: {
						Authorization: `${accessToken}`,
					},
				})
				.then((resp) => {
					dispatch({ type: MOVIMIENTOS, payload: resp.data.data });
					resolve(true);
				})
				.catch((err) => {
					reject(false);
				});
		});
	};

	///////////////////////////
	// popBody
	const popProdReg = (reg) => {
		let registro = state.prodReg;
		if (registro.length === 0) {
			registro.push(reg);
		} else {
			const index = registro.findIndex((row) => row.producto === reg.producto);
			if (index >= 0) {
				let newCant = registro[index].cantidad + reg.cantidad;
				if (newCant >= 0) {
					registro[index].cantidad = newCant;
				}
			} else {
				reg.cantidad > 0 &&
					registro.push({
						producto: reg.producto,
						cantidad: reg.cantidad,
						miniatura: reg.miniatura,
						nombre: reg.nombre,
					});
			}
		}
		setprodReg(registro);
	};
	/////////////////////////////
	//prodMov directo
	const prodRegDir = (reg) => {
		setprodReg(reg);
	};
	/////////////////////////////
	//stockchk
	const invChk = async (body) => {
		let accessToken = await getaccessToken();
		try {
			const resp = await axios.post(`/api/inventario/stockcheck/1`, body, {
				headers: {
					"Content-Type": "application/json",
					Authorization: `${accessToken}`,
				},
			});
			console.log(resp);

			return resp.data;
		} catch (error) {
			return error.response;
		}
	};
	/////////////////////////////
	// registro
	const invRegistro = async (tipo, local, folio, info) => {
		let accessToken = await getaccessToken();
		const user = window.localStorage.getItem("user");
		const body = {
			registro: tipo,
			usuario: user,
			folio: folio,
			info: info,
			ubicacion: local,
			productos: state.prodReg,
		};
		console.log(state.prodReg);

		try {
			const resp = await axios.post(`/api/inventario`, body, {
				headers: {
					"Content-Type": "application/json",
					Authorization: `${accessToken}`,
				},
			});
			console.log(resp);

			return resp.data;
		} catch (error) {
			return error.response;
		}
	};
	/////////////////////////////
	// registro
	const invRegistroDir = async (tipo, local, folio, info, productos) => {
		let accessToken = await getaccessToken();
		const user = window.localStorage.getItem("user");
		const body = {
			registro: tipo,
			usuario: parseInt(user),
			folio: folio,
			info: info,
			ubicacion: local,
			productos: productos,
		};

		try {
			const resp = await axios.post(`/api/inventario`, body, {
				headers: {
					"Content-Type": "application/json",
					Authorization: `${accessToken}`,
				},
			});

			return resp.data;
		} catch (error) {
			return error.response;
		}
	};

	/////////////////////////////
	// limpiar state
	const clInvState = async () => {
		dispatch({ type: CLEARSTATE });
	};
	return (
		<InvContext.Provider
			value={{
				inventario: state.inventario,
				invloading: state.loading,
				movimientos: state.movimientos,
				movLoading: state.movLoading,
				invBody: state.invBody,
				prodReg: state.prodReg,
				traerInventario,
				traerMovimientos,
				popProdReg,
				invRegistro,
				clInvState,
				setprodReg,
				invRegistroDir,
				invChk,
			}}
		>
			{props.children}
		</InvContext.Provider>
	);
};

export default InvState;
