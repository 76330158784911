import React, { useState } from "react";

const TdOreder = (props) => {
	const [ctrl, setCtrl] = useState(false);
	const togg = () => {
		setCtrl(!ctrl);
	};
	return (
		<tr>
			<td>{props.cant}</td>
			<td colSpan={4}>
				<p className={ctrl ? "cross OrLead" : " OrLead"}>{props.concepto}</p>
			</td>
			<td className='noprint'>
				<button onClick={togg}>X</button>
			</td>
		</tr>
	);
};

export default TdOreder;
