import React, { useState, useRef } from "react";
import Thcuenta from "../cuenta/Thcuenta.js";
import CuentaSingle from "../cuenta/CuentaSingle.js";
import AbonosSingle from "../cuenta/AbonosSingle.js";
import CuentaBusqeda from "../cuenta/CuentaBusqeda.js";
import NuevaCuenta from "../cuenta/NuevaCuenta.js";
import OrdenSingle from "../cuenta/OrdenSingle.js";
import { useReactToPrint } from "react-to-print";

const Cuentas = () => {
	const [plus, setPlus] = useState(false);
	const togNewNota = () => {
		plus ? setPlus(false) : setPlus(true);
	};
	const componentRef = useRef();
	const componentRef2 = useRef();
	const componentRef3 = useRef();
	const printa = useReactToPrint({
		content: () => componentRef.current,
	});
	const printa2 = useReactToPrint({
		content: () => componentRef2.current,
	});
	const printa3 = useReactToPrint({
		content: () => componentRef3.current,
	});
	return (
		<>
			<h1>
				Cuentas<i className='fas fa-plus-square' onClick={togNewNota}></i>
			</h1>
			<div className='grid-2'>
				<div>
					{plus && <NuevaCuenta />}
					<CuentaBusqeda />
					<Thcuenta />
				</div>
				<div>
					<CuentaSingle ref={componentRef} />
					<button className='btn btn-dark' onClick={printa}>
						Imprimir Cuenta
					</button>
					<AbonosSingle ref={componentRef2} />
					<button className='btn btn-dark' onClick={printa2}>
						Imprimir Abonos
					</button>
					<OrdenSingle ref={componentRef3} />
					<button className='btn btn-dark my-2' onClick={printa3}>
						Imprimir Orden
					</button>
				</div>
			</div>
		</>
	);
};

export default Cuentas;
