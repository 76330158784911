import React, { useState, useContext } from "react";
//import { useHistory } from 'react-router-dom';
import Spinner from "../estructura/Spinner.js";
import UserContext from "../../contexto/usuario/userContext.js";
import AlertContext from "../../contexto/alerta/alertContext";
import AutContext from "../../contexto/autentificacion/autContext.js";

const Patchusuario = (props) => {
	//////////////////////////////////////////
	// destructurado de variables y metodos
	const userContext = useContext(UserContext);
	const alertContext = useContext(AlertContext);
	const autContext = useContext(AutContext);
	const { setAlert } = alertContext;
	const { editarUsuario, userLoading, traerUsuario, usuario } = userContext;
	const { refreshSession } = autContext;

	//////////////////////////////////////
	//state interno para manejar imputs
	const [user, setUser] = useState(usuario.username);
	const [password, setPassword] = useState(false);
	const [nombre, setNombre] = useState(usuario.fullname);
	const [mail, setMail] = useState(usuario.mail);
	const [tel, setTel] = useState(usuario.tel);
	const [role, setRole] = useState(usuario.role);
	//const historia = useHistory();

	////////////////////////////////////////////////////////////
	// funcion on change captura los imputs y los pasa al state
	const onChange = (e) => {
		switch (e.target.name) {
			case "user":
				setUser(e.target.value);
				break;
			case "password":
				setPassword(e.target.value);
				break;
			case "nombre":
				setNombre(e.target.value);
				break;
			case "mail":
				setMail(e.target.value);
				break;
			case "tel":
				setTel(e.target.value);
				break;
			case "role":
				setRole(e.target.value);
				break;
			default:
				setAlert("Hubo un error", "danger");
				break;
		}
	};

	////////////////////////////////////////////////////////////
	//funcion onsubmit maneja el envio del formulario
	const onSubmit = async (e) => {
		e.preventDefault();
		const res1 = await refreshSession();
		if (res1) {
			const ufeed = await editarUsuario(
				usuario.id,
				user,
				password,
				nombre,
				mail,
				tel,
				role
			);
			setAlert(ufeed.mensaje, ufeed.color);
			setUser("");
			setPassword("");
			setNombre("");
			setMail("");
			setTel("");
		}
		await traerUsuario(usuario.id);
		props.tog();
	};

	/////////////////////////////////////////////////////////////
	// salida
	if (userLoading) {
		return <Spinner />;
	} else {
		return (
			<div className='npform'>
				<form onSubmit={onSubmit} className='npform'>
					<h2>Editar datos de usuario</h2>
					<label htmlFor='role'>Role de usuario</label>
					<select name='role' onChange={onChange}>
						<option value={role}>------</option>
						<option value='Admin'>Administrador</option>
						<option value='staff'>Colaborador</option>
						<option value='user'>Cliente</option>
					</select>
					<label htmlFor='user'>Nombre de usuario</label>
					<input
						type='text'
						name='user'
						placeholder={user}
						value={user}
						onChange={onChange}
					/>

					<label htmlFor='password'>Contraseña</label>
					<input
						type='password'
						name='password'
						placeholder='Ingrese una nueva contraseña'
						value={password}
						onChange={onChange}
					/>

					<label htmlFor='nombre'>Nombre o razón social</label>
					<input
						type='text'
						name='nombre'
						placeholder={nombre}
						value={nombre}
						onChange={onChange}
					/>

					<label htmlFor='mail'>Correo electrónico</label>
					<input
						type='text'
						name='mail'
						placeholder={mail}
						value={mail}
						onChange={onChange}
					/>

					<label htmlFor='tel'>Teléfono</label>
					<input
						type='text'
						name='tel'
						placeholder={tel}
						value={tel}
						onChange={onChange}
					/>

					<button type='submit' className='call2A btn-dark btn'>
						Modificar
					</button>
				</form>
			</div>
		);
	}
};

export default Patchusuario;
