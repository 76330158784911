import React, { useState } from "react";
import Thinventario from "../inventario/Thinventario.js";
import Thmovimientos from "../inventario/Thmovimientos.js";
import Adprod from "./Adprod.js";
import Aduser from "./Aduser.js";
import Corte from "../cuenta/Corte.js";
import Gastos from "../cuenta/Gastos.js";

const Admin = () => {
	const [toggle, setToggle] = useState(1);

	return (
		<div>
			<h1 className='my'>Administración</h1>
			<div className='grid-5'>
				<button className='btn btn-dark' onClick={() => setToggle(1)}>
					Productos
				</button>
				<button className='btn btn-dark' onClick={() => setToggle(2)}>
					Usuarios
				</button>
				<button className='btn btn-dark' onClick={() => setToggle(3)}>
					Inventario
				</button>
				<button className='btn btn-dark' onClick={() => setToggle(4)}>
					Movimientos
				</button>
				<button className='btn btn-dark' onClick={() => setToggle(5)}>
					Corte
				</button>
				<button className='btn btn-dark' onClick={() => setToggle(6)}>
					Gastos
				</button>
			</div>
			{toggle === 1 ? (
				<Adprod />
			) : toggle === 2 ? (
				<Aduser />
			) : toggle === 3 ? (
				<Thinventario />
			) : toggle === 4 ? (
				<Thmovimientos />
			) : toggle === 5 ? (
				<Corte />
			) : (
				<Gastos />
			)}
		</div>
	);
};

export default Admin;
