import React, { useContext, useState, useRef, useEffect } from "react";
import CuentaContext from "../../contexto/cuentas/cuentaContext";
import Spinner from "../estructura/Spinner.js";
import Tdcuenta from "./Tdcuenta.js";
import AlertContext from "../../contexto/alerta/alertContext.js";
import { useReactToPrint } from "react-to-print";
import AbionosDia from "./AbionosDia.js";
import Thgastos from "./Thgastos.js";

const Corte = () => {
	////////////////////////////////////////
	//ref para imprimir
	const componentRefc = useRef();
	const printa = useReactToPrint({
		content: () => componentRefc.current,
	});
	////////////////////////////////////////
	//contextos
	const cuentaContext = useContext(CuentaContext);
	const { cuentaBusqByFecha, abonosBusqByFecha, gastosBusqByFecha, gastos } =
		cuentaContext;
	const alertContext = useContext(AlertContext);
	const { setAlert } = alertContext;
	var fechaActualISO = new Date().toISOString();
	var fechaActual = new Date(fechaActualISO);
	fechaActual.setHours(fechaActual.getHours() - 6);
	var año = fechaActual.getFullYear();
	var mes = fechaActual.getMonth() + 1; // Se suma 1 porque los meses van de 0 a 11
	var dia = fechaActual.getDate();
	mes = mes < 10 ? "0" + mes : mes;
	dia = dia < 10 ? "0" + dia : dia;
	var hoy = año + "-" + mes + "-" + dia;
	const [cuentasDelDia, setCuentasDelDia] = useState([]);
	const [abonosDelDia, setAbonosDelDia] = useState([]);
	const [abonosF, setAbonosF] = useState([]);
	const [cuentasF, setCuentasF] = useState([]);
	const [gastosF, setGastosF] = useState([]);
	const [corte, setCorte] = useState([]);
	const [total, setTotal] = useState(0);
	const [selSuc, setSelSuc] = useState("s");
	const [swi, setSwi] = useState(false);
	const [fechaV, setFechaV] = useState(hoy);
	const calcCorte = (data) => {
		let tipos = {};
		let Tot = 0;
		data.forEach((row, i) => {
			console.log(row);

			const monto = parseFloat(row.monto);
			if (!isNaN(row.monto) && row.status === 1) {
				if (row.tipo in tipos) {
					tipos[row.tipo] += monto;
				} else {
					tipos[row.tipo] = monto;
				}
				Tot += monto;
			}
		});
		setCorte(tipos);
		setTotal(Tot);
	};
	//////////////////////////////
	//Onchange fecha
	const nuevaFecha = (e) => {
		setFechaV(e.target.value);
		traerCuentas(e.target.value);
		traerAbonos(e.target.value);
		setSelSuc("");
	};
	const onChangeSuc = async (e) => {
		setSelSuc(e.target.value);
		if (e.target.value === "todos") {
			setAbonosF(abonosDelDia);
			setCuentasF(cuentasDelDia);
			calcCorte(abonosDelDia);
		} else {
			const abosBySuc = abonosDelDia.filter(
				(row) => row.sucursal === e.target.value
			);
			const ctasBySuc = cuentasDelDia.filter(
				(row) => row.sucursal === e.target.value
			);
			setAbonosF(abosBySuc);
			setCuentasF(ctasBySuc);
			calcCorte(abosBySuc);
		}
	};
	///////////////////////////////
	//metodos
	const traerCuentas = async (d) => {
		setSwi(true);
		const resp = await cuentaBusqByFecha(d, d);
		if (resp.data.success) {
			setCuentasDelDia(resp.data.data);
			setCuentasF(resp.data.data);
		} else {
			setAlert("no se encontraron Cuentas del Día " + d, "danger");
			setCuentasDelDia([]);
			setFechaV(hoy);
		}
	};
	const traerAbonos = async (d) => {
		setSwi(true);
		const resp = await abonosBusqByFecha(d, d);
		if (resp.data.success) {
			setAbonosDelDia(resp.data.data);
			setAbonosF(resp.data.data);
			calcCorte(resp.data.data);
		} else {
			setAlert("No se encontraron Abonos del dia " + d, "danger");
			setAbonosDelDia([]);
			setFechaV(hoy);
		}
	};
	const traerGastos = async (d) => {
		setSwi(true);
		const resp = await gastosBusqByFecha(d, d);
		console.log(resp);
		if (resp.data.success) {
			setGastosF(resp.data.data);
		}
	};
	///////////////////////////
	//inicializa
	if (cuentasDelDia.length === 0 && abonosDelDia.length === 0 && !swi) {
		traerCuentas(fechaV);
		traerAbonos(fechaV);
		gastosBusqByFecha(fechaV);
		traerGastos(fechaV);
	}
	useEffect(() => {}, [selSuc]);
	return (
		<div className='grid-2'>
			<div>
				<h2>Notas del Dia</h2>
				<table className='my-1 w-100'>
					<thead>
						<tr>
							<th>Folio</th>
							<th>Nombre</th>
							<th>Fecha</th>
							<th>Monto</th>
							<th>CNL</th>
						</tr>
					</thead>
					<tbody>
						{cuentasF.map((cta) => (
							<Tdcuenta key={cta.folio} cuenta={cta} />
						))}
					</tbody>
				</table>
			</div>
			<div>
				<h2>Corte del {fechaV}</h2>
				<div className='grid-3 my'>
					<input
						type='date'
						className='m0 noprint'
						value={fechaV}
						onChange={nuevaFecha}
					/>
					<select name='sucur' id='' value={selSuc} onChange={onChangeSuc}>
						<option value='' disabled selected>
							Sucursal
						</option>
						<option value='todos'>Todas</option>
						<option value='salida'>Salida S.L.P.</option>
						<option value='centro'>Centro</option>
						<option value='arriaga'>Arriaga</option>
					</select>
					<button className='btn btn-dark' onClick={printa}>
						Imprimir
					</button>
				</div>
				<AbionosDia
					abonosDelDia={abonosF}
					corte={corte}
					total={total}
					fechaV={fechaV}
					ref={componentRefc}
					suc={selSuc}
					gastos={gastos}
					notas={cuentasF}
				/>
			</div>
		</div>
	);
};

export default Corte;
