import React from "react";
import PropTypes from "prop-types";
const Tdmovimientos = ({
	mov: {
		id,
		fullname,
		registro,
		designacion,
		cantidad,
		nombre,
		fecha,
		folio,
		info,
	},
}) => {
	const style = `mov-${registro} mov-li`;
	let reg = "eliminó";
	let color = "grey";
	if (registro === "entrada") {
		reg = "ingresó";
		color = "green";
	}
	if (registro === "salida") {
		reg = "retiró";
		color = "red";
	}
	if (registro === "apartado") {
		reg = "apartó";
		color = "blue";
	}

	return (
		<li id={id} key={id} className={style}>
			{fullname} <strong style={{ color: color }}>{reg}</strong> {cantidad} "
			{nombre}" en <strong>{designacion}</strong> con folio:
			<strong>{folio !== "" && folio}</strong> el dia {fecha.substring(0, 10)} a
			las {fecha.substring(11)} Observacion: <strong>{info !== "" && info}</strong>
		</li>
	);
};
Tdmovimientos.propTypes = {
	mov: PropTypes.object.isRequired,
};
export default Tdmovimientos;
