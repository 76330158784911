import React, { useContext, useEffect } from "react";
import UserContext from "../../contexto/usuario/userContext.js";
import AutContext from "../../contexto/autentificacion/autContext.js";
import Tdusuarios from "./Tdusuarios.js";
import Spinner from "../estructura/Spinner.js";

const Thusuarios = () => {
	///////////////////////////////
	//contextos
	const autContext = useContext(AutContext);
	const { refreshSession } = autContext;
	const userContext = useContext(UserContext);
	const { usuarios, userLoading, traerUsuarios } = userContext;

	//ejecutamos la funcion para poblar
	useEffect(() => {
		const x = refreshSession();
		x.then(() => {
			if (usuarios.length === 0) {
				traerUsuarios();
			}
		});
		//eslint-disable-next-line
	}, []);

	if (userLoading) {
		return <Spinner />;
	} else {
		return (
			<div>
				<table className='my-1 w-100'>
					<thead>
						<tr>
							<th>Usuario</th>
							<th>Nombre</th>
							<th>Role</th>
							<th>Del</th>
						</tr>
					</thead>
					<tbody>
						{usuarios.map((usr) => (
							<Tdusuarios key={usr.id} usr={usr} />
						))}
					</tbody>
				</table>
			</div>
		);
	}
};

export default Thusuarios;
