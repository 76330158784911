import React, { useState, useContext } from "react";
import CuentaContext from "../../contexto/cuentas/cuentaContext";
import AlertContext from "../../contexto/alerta/alertContext";
import Thgastos from "../cuenta/Thgastos";
const Gastos = () => {
	////////////////////////////////
	//Fecha
	var fechaActualISO = new Date().toISOString();
	var fechaActual = new Date(fechaActualISO);
	fechaActual.setHours(fechaActual.getHours());
	var año = fechaActual.getFullYear();
	var mes = fechaActual.getMonth() + 1; // Se suma 1 porque los meses van de 0 a 11
	var dia = fechaActual.getDate();
	mes = mes < 10 ? "0" + mes : mes;
	dia = dia < 10 ? "0" + dia : dia;
	var hoy = año + "-" + mes + "-" + dia;
	///
	/////////////////////////////////
	const cuentaContext = useContext(CuentaContext);
	const alertContext = useContext(AlertContext);
	const { crearGasto, gastosBusqByFecha, gastos } = cuentaContext;
	const { setAlert } = alertContext;
	const [formData, setFormData] = useState({
		monto: "",
		usuario: "",
		motivo: "",
		sucursal: "salida", // default value
	});

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({
			...formData,
			[name]: value,
		});
	};
	var x;
	if (gastos.length === 0) {
		gastosBusqByFecha(hoy, hoy);
	}
	const handleSubmit = async (e) => {
		e.preventDefault();
		const resp = await crearGasto(formData);
		console.log(resp);
		if (resp.success) {
			setAlert("Gasto creado con Éxito", "success");
			gastosBusqByFecha(hoy, hoy);
		}
		// You can do something with the form data here
	};

	return (
		<div className='py-2'>
			<div className='SinProd card p-1'>
				<h2 className='my'>Igresar Gastos</h2>
				<i className='my small' style={{ marginBottom: "0.5rem", color: "grey" }}>
					Para ingresar un gasto digite el monto usuario motivo y selecciones su
					sucursal
				</i>
				<form onSubmit={handleSubmit} className='grid-2'>
					<div>
						<input
							type='number'
							id='monto'
							name='monto'
							placeholder='$0.00'
							value={formData.monto}
							onChange={handleChange}
							className='m0'
						/>
					</div>
					<div>
						<input
							type='text'
							id='usuario'
							name='usuario'
							placeholder='Usuario'
							value={formData.usuario}
							onChange={handleChange}
							className='m0'
						/>
					</div>
					<div>
						<input
							type='text'
							id='motivo'
							name='motivo'
							placeholder='Motivo'
							value={formData.motivo}
							onChange={handleChange}
							className='m0'
						/>
					</div>
					<div>
						<select
							id='lugar'
							name='sucursal'
							value={formData.lugar}
							onChange={handleChange}
						>
							<option value='salida'>Salida</option>
							<option value='centro'>Centro</option>
							<option value='arriaga'>Arriaga</option>
						</select>
					</div>
					<button
						type='submit'
						className='btn btn-dark gspan-2'
						style={{ margin: "0", marginRight: "0" }}
					>
						Ingresar gasto
					</button>
				</form>
				<Thgastos datos={gastos} fecha={hoy} refresh={gastosBusqByFecha} />
			</div>
		</div>
	);
};

export default Gastos;
