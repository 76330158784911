import React, { useState, useContext, useEffect } from "react";
import AutContext from "../../contexto/autentificacion/autContext.js";
import { Link, useHistory } from "react-router-dom";
import Spinner from "./Spinner.js";

const Login = () => {
	const [user, setUser] = useState("");
	const [password, setPassword] = useState("");
	const autContext = useContext(AutContext);
	const history = useHistory();
	const { logstatus, login, autloading } = autContext;

	const onChange = (e) => {
		if (e.target.name === "user") {
			setUser(e.target.value);
		} else if (e.target.name === "password") {
			setPassword(e.target.value);
		}
	};

	const onSubmit = async (e) => {
		e.preventDefault();
		const resp = await login(user, password);
		console.log(resp);
		setUser("");
		setPassword("");
	};

	useEffect(() => {
		if (logstatus.loged) {
			history.push("/");
		}
		//eslint-disable-next-line
	}, [logstatus, autloading]);

	if (autloading === true) {
		return <Spinner />;
	} else {
		return (
			<div className='login page-wrapp'>
				<form onSubmit={onSubmit} className='loginForm'>
					<h2 className='large'>Bienvenido</h2>
					<h3>Ingrese su nombre de Usuario y Contraseña</h3>
					<label className='my bold' htmlFor='user'>
						Nombre de usuario:
					</label>
					<input
						type='text'
						name='user'
						placeholder='Ingrese su usuario'
						value={user}
						onChange={onChange}
					/>
					<label className='my bold' htmlFor='password'>
						Contraseña:
					</label>
					<input
						type='password'
						name='password'
						placeholder='Ingrese su contraseña'
						value={password}
						onChange={onChange}
					/>
					<input type='submit' className='btn btn-block btn-dark' value='Ingresar' />
				</form>
				<h3>Si aun no eres cliente!</h3>
				<Link to='/registro'>
					<button className='call2A btn'>Registrate</button>
				</Link>
			</div>
		);
	}
};

export default Login;
