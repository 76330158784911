import React, { useContext, useState } from "react";
import ProdContext from "../../contexto/producto/prodContext.js";
import AlertContext from "../../contexto/alerta/alertContext.js";

const BusquedaDisp = () => {
	////////////////////////////////////////////////////////////////
	// definicion de contextos
	const prodContext = useContext(ProdContext);
	const alertContext = useContext(AlertContext);

	////////////////////////////////////////////////////////////////////////
	//destructuracion de metodos y variables
	const [texto, setTexto] = useState("");
	const { buscarProductos, traerProductos, filtrarDispByCat } = prodContext;
	const { setAlert } = alertContext;

	//funcion de busqueda
	const onSubmit = async (e) => {
		e.preventDefault();
		if (texto === "") {
			setAlert("El campo de busqueda está vacio", "danger");
		} else {
			try {
				const resp = await buscarProductos(2, texto);
				if (resp.success) {
					setAlert(resp.mensaje, resp.tipo);
					setTexto("");
				}
			} catch (r) {
				setAlert("no se encontraron productos con esas caracteristicas", "danger");
				traerProductos();
				setTexto("");
			}
		}
	};
	// definicion de onchange
	const onChange = (e) => setTexto(e.target.value);
	////////////////////////////
	// bbc
	const bbyc = async (cid) => {
		const resp = await filtrarDispByCat(cid);
		if (resp === "todos los productos") {
			setAlert(resp, "success");
		}
		if (resp === "La categoría no tiene productos") {
			setAlert(resp, "danger");
		}
	};
	return (
		<div>
			<form onSubmit={onSubmit} className='formBusqueda'>
				<input
					type='text'
					name='text'
					placeholder='Buscar producto'
					value={texto}
					onChange={onChange}
					className='my'
				/>
				<input
					type='submit'
					name='Buscar'
					className='btn btn-block my'
					value='Buscar'
				/>
				{prodContext.busqueda && (
					<button
						className='btn btn-dark btn-block'
						onClick={prodContext.limpiarProductos}
					>
						Limpiar busqueda
					</button>
				)}
			</form>
			<div className='flex-line'>
				<button
					className='btn btn-dark my'
					onClick={(e) => {
						bbyc(1);
					}}
				>
					Salas
				</button>
				<button
					className='btn btn-dark  my'
					onClick={(e) => {
						bbyc(2);
					}}
				>
					Recamaras
				</button>
				<button
					className='btn btn-dark  my'
					onClick={(e) => {
						bbyc(3);
					}}
				>
					Colchones
				</button>
				<button
					className='btn btn-dark  my'
					onClick={(e) => {
						bbyc(4);
					}}
				>
					Roperos
				</button>
				<button
					className='btn btn-dark  my'
					onClick={(e) => {
						bbyc(5);
					}}
				>
					Comedores
				</button>
				<button
					className='btn btn-dark  my'
					onClick={(e) => {
						bbyc(6);
					}}
				>
					Cocinas
				</button>
				<button
					className='btn btn-dark  my'
					onClick={(e) => {
						bbyc(7);
					}}
				>
					Alacenas
				</button>
				<button
					className='btn btn-dark  my'
					onClick={(e) => {
						bbyc(8);
					}}
				>
					Libreros
				</button>
				<button
					className='btn btn-dark  my'
					onClick={(e) => {
						bbyc(9);
					}}
				>
					Electrodomésticos
				</button>
				<button
					className='btn btn-dark  my'
					onClick={(e) => {
						bbyc(10);
					}}
				>
					Electrónica
				</button>
				<button
					className='btn btn-dark  my'
					onClick={(e) => {
						bbyc(11);
					}}
				>
					Motos
				</button>
				<button
					className='btn btn-dark  my'
					onClick={(e) => {
						bbyc(13);
					}}
				>
					Linea Blanca
				</button>
				<button
					className='btn btn-dark  my'
					onClick={(e) => {
						bbyc(12);
					}}
				>
					Otros
				</button>
				<button
					className='btn btn-dark  my'
					onClick={(e) => {
						bbyc(0);
					}}
				>
					Todos
				</button>
			</div>
		</div>
	);
};

export default BusquedaDisp;
