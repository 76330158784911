import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import InvContext from "../../contexto/inventario/invContext.js";

const Elementoregistro = ({ prod: { id, Nombre, Miniatura, Descripcion } }) => {
	//////////////////////////////////
	// contextos
	const invContext = useContext(InvContext);
	const { popProdReg } = invContext;

	const [nombre] = useState(Nombre);
	const [miniatura] = useState(Miniatura);
	//////////////////////////////////
	// añadir +1
	const plusBtn = async (e) => {
		e.stopPropagation();
		const payload = {
			producto: id,
			cantidad: 1,
			miniatura: miniatura,
			nombre: nombre,
		};
		popProdReg(payload);
	};

	//////////////////////////////////
	// eliminar -1
	const minusBtn = async (e) => {
		e.stopPropagation();
		console.log(nombre);
		const payload = {
			producto: id,
			cantidad: -1,
			miniatura: miniatura,
			nombre: nombre,
		};
		popProdReg(payload);
	};

	return (
		<tr id={id} key={id} className='tdlink'>
			<td>
				<i onClick={minusBtn} className='fas fa-minus-circle medium'></i>
			</td>
			<td className=''>
				<img src={Miniatura} alt={Descripcion} />
			</td>
			<td className='text-left'>{Nombre}</td>
			<td>
				<i
					onClick={plusBtn}
					className='fas fa-plus-circle medium success-color'
				></i>
			</td>
		</tr>
	);
};
Elementoregistro.propTypes = {
	prod: PropTypes.object.isRequired,
};

export default Elementoregistro;
