import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const Producto = ({ prod: { id, Nombre, Imagen, Precio } }) => {
	return (
		<div className='card'>
			<div className='priceImageCombo'>
				<Link to={`/productos/${id}`}>
					<div className='imgContainer'>
						<img src={Imagen} alt={Nombre} className='card-thumb cover' />
					</div>
				</Link>
				{/*<h4 className='pprice'>${Precio}</h4>*/}
			</div>
			<h3 className='pname'>{Nombre}</h3>
		</div>
	);
};
Producto.propTypes = {
	prod: PropTypes.object.isRequired,
};
export default Producto;
