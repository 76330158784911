import React, { useContext, useEffect, useState } from "react";
import Elementoregistro from "./Elementoregistro.js";
import ProdContext from "../../contexto/producto/prodContext.js";
import Spinner from "../estructura/Spinner.js";

const Registroinventario = () => {
	const prodContext = useContext(ProdContext);
	const { productos, traerProductos, loading } = prodContext;

	///////////////////////////
	// paginacion
	const [pagina, setPagina] = useState(1);
	const [ppp] = useState(12);
	const [limiteDpaginas] = useState(5);
	const [limiteMaximoDpn, setlimiteMaximoDpn] = useState(5);
	const [limiteMinimoDpn, setlimiteMinimoDpn] = useState(0);

	let ptotal = Object.keys(productos).length;
	let paginas = [];
	for (let i = 1; i <= Math.ceil(ptotal / ppp); i++) {
		paginas.push(i);
	}

	///////////////////////////
	//componente de navegacion
	const irApagina = (e) => {
		setPagina(Number(e.target.id));
	};

	//mapeamos el array de páginas para crear el boton que le corresponde
	const numerosDpagina = paginas.map((numero) => {
		if (numero < limiteMaximoDpn + 1 && numero > limiteMinimoDpn) {
			return (
				<li
					key={numero}
					id={numero}
					onClick={irApagina}
					className={pagina === numero ? "activa" : null}
				>
					{numero}
				</li>
			);
		} else {
			return null;
		}
	});

	/////////////////////////
	//paginas actuales
	const indiceUltimo = pagina * ppp;
	const indicePrimero = indiceUltimo - ppp;
	const productosPaginados = productos.slice(indicePrimero, indiceUltimo);

	////////////////////////
	//prev y sig
	const onclSig = () => {
		setPagina(pagina + 1);
		if (pagina + 1 > limiteMaximoDpn) {
			setlimiteMaximoDpn(limiteMaximoDpn + limiteDpaginas);
			setlimiteMinimoDpn(limiteMinimoDpn + limiteDpaginas);
		}
	};
	const onclPrev = () => {
		setPagina(pagina - 1);
		if ((pagina - 1) % limiteDpaginas === 0) {
			setlimiteMaximoDpn(limiteMaximoDpn - limiteDpaginas);
			setlimiteMinimoDpn(limiteMinimoDpn - limiteDpaginas);
		}
	};

	//////////////////////
	//Suspensivos
	let suspensivosPlus = null;
	if (paginas.length > limiteMaximoDpn) {
		suspensivosPlus = (
			<li
				key='pre'
				id='pre'
				onClick={onclSig}
				className={pagina === paginas[paginas.length - 1] ? "hid" : "vis"}
			>
				&hellip;
			</li>
		);
	}
	let suspensivosminus = null;
	if (paginas.length > limiteMaximoDpn) {
		suspensivosminus = (
			<li
				onClick={onclPrev}
				key='susm'
				id='susm'
				className={pagina === paginas[0] ? "hid" : "vis"}
			>
				&hellip;
			</li>
		);
	}

	//////////////////////////////////////
	//ejecutamos la funcion para poblar
	useEffect(() => {
		if (ptotal === 0) {
			traerProductos();
		}
		//eslint-disable-next-line
	}, [productos]);

	if (loading) {
		return <Spinner />;
	} else {
		return (
			<div id='' className='contenedor card'>
				<table className='my-1 w-100'>
					<thead>
						<tr>
							<th>Quitar</th>
							<th>Imagen</th>
							<th>Nombre</th>
							<th>Añadir</th>
						</tr>
					</thead>
					<tbody>
						{productosPaginados.map((prod) => (
							<Elementoregistro key={prod.id} prod={prod} />
						))}
					</tbody>
				</table>
				<ul className='numerosPaginacion'>
					<li key='prev' id='prev'>
						<button
							onClick={onclPrev}
							disabled={pagina === paginas[0] ? true : false}
						>
							<span>&#171;</span> Prev
						</button>
					</li>
					{suspensivosminus}
					{numerosDpagina}
					{suspensivosPlus}
					<li key='next' id='next'>
						<button
							onClick={onclSig}
							disabled={pagina === paginas[paginas.length - 1] ? true : false}
						>
							Sig <span>&#187;</span>
						</button>
					</li>
				</ul>
			</div>
		);
	}
};

export default Registroinventario;
