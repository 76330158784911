export const PRODUCTOS = "PRODUCTOS";
export const PRODUCTO = "PRODUCTO";
export const SET_LOADING = "SET_LOADING";
export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const BUSQUEDA = "BUSQUEDA";
export const LIMPIAR_PRODUCTOS = "LIMPIAR_PRODUCTOS";
export const PRODUCTO_FEEDBACK = "PRODUCTO_FEEDBACK";
export const LIMPIAR_PRODUCTO_FEEDBACK = "LIMPIAR_PRODUCTO_FEEDBACK";
export const LOG_FEEDBACK = "LOG_FEEDBACK";
export const LIMPIAR_LOG_FEEDBACK = "LIMPIAR_LOG_FEEDBACK";
export const USUARIOS = "USUARIOS";
export const USUARIO = "USUARIO";
export const CLEARU = "CLEARU";
export const CATEGORIAS = "CATEGORIAS";
export const UNSET_LOADING = "UNSET_LOADING";
export const INVENTARIO = "INVENTARIO";
export const SET_MOVLOADING = "SET_MOVLOADING";
export const MOVIMIENTOS = "MOVIMIENTOS";
export const INVPRODREG = "INVPRODREG";
export const CLEARSTATE = "CLEARSTATE";
export const CUENTAS = "CUENTAS";
export const CUENTA = "CUENTA";
export const CLIENTES = "CLIENTES";
export const CLIENTE = "CLIENTE";
export const ABONOS = "ABONOS";
export const DISPONIBLES = "DISPONIBLES";
export const BUSQDISP = "BUSQDISP";
export const LIMPIAR_PRODUCTOSDISP = "LIMPIAR_PRODUCTOSDISP";
export const GASTOS = "GASTOS";
