import React, { useState, useContext } from "react";
import Busqueda from "../productos/Busqueda";
import Registroinventario from "../inventario/Registroinventario.js";
import Vistaregistro from "../inventario/Vistaregistro.js";
import AlertContext from "../../contexto/alerta/alertContext";
import InvContext from "../../contexto/inventario/invContext.js";
import Thinventario from "../inventario/Thinventario";
import Thmovimientos from "../inventario/Thmovimientos";
const Inventario = () => {
	///////////////////////////
	// Contexto de inventario
	const invContext = useContext(InvContext);
	const { invRegistro, clInvState, setprodReg } = invContext;
	const alertContext = useContext(AlertContext);
	const { setAlert } = alertContext;
	//////////////////////////
	// Init state
	const [regTipo, setregTipo] = useState("entrada");
	const [regDestino, setregDestino] = useState("2");
	const [regFolio, setregFolio] = useState("");
	const [regInfo, setregInfo] = useState("");

	const locs = [
		"null",
		"Fuera",
		"Matriz",
		"Centro",
		"Arriaga",
		"Bodega1",
		"Bodega2",
		"Bodega3",
	];
	//////////////////////////
	//Boton de nuevo registro
	const [newReg, setNewReg] = useState(false);
	const togNewReg = () => {
		newReg ? setNewReg(false) : setNewReg(true);
	};
	const [togInv, settogInv] = useState(false);
	const togtogInv = () => {
		togInv ? settogInv(false) : settogInv(true);
	};
	const [togMov, settogMov] = useState(false);
	const togtogMov = () => {
		togMov ? settogMov(false) : settogMov(true);
	};
	const [disProductos, setdisProductos] = useState(false);
	const togdisProductos = () => {
		disProductos ? setdisProductos(false) : setdisProductos(true);
	};

	//////////////////////////
	// form control
	const onChangeregTipo = (e) => setregTipo(e.target.value);
	const onChangeregDestino = (e) => setregDestino(e.target.value);
	const onChangeregFolio = (e) => setregFolio(e.target.value);
	const onChangeregInfo = (e) => setregInfo(e.target.value);

	/////////////////////////
	// Nuevo Registro
	const nuevoRegistro = (e) => {
		e.preventDefault();
		togdisProductos();
		togNewReg();
	};

	const reSet = () => {
		setregTipo("entrada");
		setregDestino("2");
		setregFolio("");
		setregInfo("");
		setdisProductos(false);
		togNewReg();
		const x = [];
		setprodReg(x);
	};
	///////////////////////
	//Añadir registro
	const onClickReg = async () => {
		const validar = prompt("ingrese su clave");
		if (validar == "1234") {
			console.log(regDestino);
			const feed = await invRegistro(regTipo, regDestino, regFolio, regInfo);
			console.log(feed);
			if (feed.success === true) {
				clInvState();
				setAlert(feed.messages, "success");
				setNewReg(false);
				setdisProductos(false);
				reSet();
			} else {
				clInvState();
				setAlert(feed.data.messages, "danger");
				setNewReg(false);
				setdisProductos(false);
				reSet();
			}
		} else {
			setAlert("clave incorrecta", "danger");
			reSet();
		}
	};

	return (
		<div>
			<h1>
				Registrar movimiento de inventario
				<i className='fas fa-plus-square' onClick={togNewReg}></i>
			</h1>
			{newReg ? (
				<form onSubmit={nuevoRegistro} className='grid-4 container'>
					<div>
						<label htmlFor='regTipo'>Tipo de registro</label>
						<select name='regTipo' className='my bigimput' onChange={onChangeregTipo}>
							<option value='entrada'>Entrada</option>
							<option value='salida'>Salida</option>
							<option value='eliminar'>eliminar</option>
						</select>
					</div>
					<div>
						<label htmlFor='Destino'>Destino</label>
						<select className='my bigimput' onChange={onChangeregDestino}>
							<option value='2'>Matriz</option>
							<option value='3'>Centro</option>
							<option value='4'>Arriaga</option>
							<option value='5'>Bodega1</option>
							<option value='6'>Bodega2</option>
							<option value='7'>Bodega3</option>
							<option value='1'>Fuera</option>
						</select>
					</div>
					<div>
						<label htmlFor='folio'>folio relacionado</label>
						<input type='text' className='my bigimput' onChange={onChangeregFolio} />
					</div>
					<div>
						<label htmlFor='folio'>Observación</label>
						<input type='text' className='my bigimput' onChange={onChangeregInfo} />
					</div>
					<input
						type='submit'
						className='btn btn-dark btn-block my gspan-5'
						value='Crear Registro'
					/>
				</form>
			) : null}
			<div className='container grid-3'>
				<div className='gspan-2'>
					<div className={disProductos ? "block" : "hid"}>
						{disProductos && (
							<p className='lead'>
								Nuevo registro de <strong>{regTipo}</strong> en{" "}
								<strong>{locs[regDestino]}</strong>{" "}
								{regFolio.length > 0 && (
									<>
										Folio: <strong>{regFolio}</strong>{" "}
									</>
								)}
								{regInfo.length > 0 && (
									<>
										info: <strong>{regInfo}</strong>{" "}
									</>
								)}
							</p>
						)}
						<Busqueda />
						<Registroinventario />
					</div>
				</div>
				<div className={disProductos ? "block" : "hid"}>
					<Vistaregistro />
					<button onClick={reSet} className='btn btn-light btn-block my'>
						Reiniciar
					</button>
					<button onClick={onClickReg} className='btn btn-dark btn-block my'>
						Enviar Registro
					</button>
				</div>
			</div>
			<div>
				<h2>
					Inventario <i className='fas fa-plus-square' onClick={togtogInv}></i>
				</h2>
				{togInv && <Thinventario />}
			</div>
			<div className='gspan-2'>
				<h2>
					Movimientos de inventario{" "}
					<i className='fas fa-plus-square' onClick={togtogMov}></i>
				</h2>
				{togMov && <Thmovimientos />}
			</div>
		</div>
	);
};

export default Inventario;
