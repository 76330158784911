import {
	INVENTARIO,
	SET_LOADING,
	MOVIMIENTOS,
	SET_MOVLOADING,
	INVPRODREG,
	CLEARSTATE,
} from "../types.js";
const invReducer = (state, action) => {
	switch (action.type) {
		case INVENTARIO:
			return {
				...state,
				inventario: action.payload,
				invloading: false,
			};
		case SET_LOADING:
			return {
				...state,
				invloading: true,
			};
		case MOVIMIENTOS:
			return {
				...state,
				movimientos: action.payload,
				movLoading: false,
			};
		case SET_MOVLOADING:
			return {
				...state,
				movLoading: true,
			};
		case INVPRODREG:
			return {
				...state,
				prodReg: action.payload,
			};
		case CLEARSTATE:
			return {
				inventario: [],
				invloading: false,
				movimientos: [],
				movLoading: false,
				invBody: {},
				prodReg: [],
			};
		default:
			return state;
	}
};
export default invReducer;
