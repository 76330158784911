import {
	PRODUCTOS,
	PRODUCTO,
	SET_LOADING,
	BUSQUEDA,
	LIMPIAR_PRODUCTOS,
	PRODUCTO_FEEDBACK,
	LIMPIAR_PRODUCTO_FEEDBACK,
	CATEGORIAS,
	UNSET_LOADING,
	DISPONIBLES,
	BUSQDISP,
	LIMPIAR_PRODUCTOSDISP,
} from "../types.js";
const prodReducer = (state, action) => {
	switch (action.type) {
		case PRODUCTOS:
			return {
				...state,
				productos: action.payload,
				loading: false,
				productoFeed: null,
			};
		case DISPONIBLES:
			return {
				...state,
				disponibles: action.payload,
				loading: false,
				productoFeed: null,
			};
		case BUSQUEDA:
			return {
				...state,
				productos: action.payload,
				loading: false,
				busqueda: true,
				productoFeed: null,
			};
		case BUSQDISP:
			return {
				...state,
				disponibles: action.payload,
				loading: false,
				busqueda: true,
				productoFeed: null,
			};
		case PRODUCTO:
			return {
				...state,
				producto: action.payload,
				loading: false,
				productoFeed: null,
			};
		case PRODUCTO_FEEDBACK:
			return {
				...state,
				productoFeed: action.payload,
				loading: false,
			};
		case LIMPIAR_PRODUCTO_FEEDBACK:
			return {
				...state,
				productoFeed: null,
				loading: false,
			};
		case LIMPIAR_PRODUCTOS:
			return {
				...state,
				productos: [],
				loading: false,
				busqueda: false,
				productoFeed: null,
			};
		case LIMPIAR_PRODUCTOSDISP:
			return {
				...state,
				disponibles: [],
				loading: false,
				busqueda: false,
				productoFeed: null,
			};
		case SET_LOADING:
			return {
				...state,
				loading: true,
				productoFeed: null,
			};
		case UNSET_LOADING:
			return {
				...state,
				loading: false,
			};
		case CATEGORIAS:
			return {
				...state,
				categorias: action.payload,
				loading: false,
			};
		default:
			return state;
	}
};
export default prodReducer;
