import React from "react";

const Elevistaregistro = ({ reg: { producto, nombre, cantidad } }) => {
	return (
		<li id={producto} key={producto} className='text-left reg-li'>
			<strong className='medium'>{cantidad} </strong>
			{nombre}
		</li>
	);
};

export default Elevistaregistro;
