import React, { useContext, useEffect } from "react";
import Producto from "./Producto.js";
import ProdContext from "../../contexto/producto/prodContext.js";
import Spinner from "../estructura/Spinner.js";

const Productos = () => {
	const prodContext = useContext(ProdContext);
	const { disponibles, traerProductosDisp, loading } = prodContext;

	//ejecutamos la funcion para poblar
	console.log(disponibles.length);

	useEffect(() => {
		if (disponibles.length === 0) {
			console.log("entra");

			traerProductosDisp();
		}
		//eslint-disable-next-line
	}, [disponibles]);

	if (loading) {
		return <Spinner />;
	} else {
		return (
			<div id='productos' className='contenedor'>
				{disponibles.map((prod) => (
					<Producto key={prod.id} prod={prod} />
				))}
			</div>
		);
	}
};

export default Productos;
