import React, { useReducer } from "react";
import axios from "axios";
import ProdContext from "./prodContext.js";
import ProdReducer from "./prodReducer.js";
import {
	PRODUCTOS,
	SET_LOADING,
	PRODUCTO,
	BUSQUEDA,
	LIMPIAR_PRODUCTOS,
	PRODUCTO_FEEDBACK,
	LIMPIAR_PRODUCTO_FEEDBACK,
	CATEGORIAS,
	UNSET_LOADING,
	DISPONIBLES,
	BUSQDISP,
	LIMPIAR_PRODUCTOSDISP,
} from "../types.js";

const ProdState = (props) => {
	/////////////////////////////
	//se inicializa de state
	const initialState = {
		productos: [],
		disponibles: [],
		categorias: [],
		producto: {},
		productoFeed: null,
		busqueda: false,
		loading: false,
	};

	/////////////////////////////
	//traer access token
	const getaccessToken = async () => {
		return window.localStorage.getItem("access_token");
	};

	/////////////////////////////
	//se declara el dispatch
	const [state, dispatch] = useReducer(ProdReducer, initialState);

	/////////////////////////////
	//Función para crear producto
	const crearProducto = async (
		nombre,
		categoria_id,
		precio,
		descripcion,
		imagen
	) => {
		let accessToken = await getaccessToken();
		let pid = null;
		return new Promise((resolve, reject) => {
			setLoading();
			const body = {
				nombre: nombre,
				descripcion: descripcion,
				precio: precio,
				categoria_id: categoria_id,
			};
			axios
				.post(`/api/productos`, body, {
					headers: {
						"Content-Type": "application/json",
						Authorization: `${accessToken}`,
					},
				})
				.then((resp) => {
					dispatch({
						type: PRODUCTO_FEEDBACK,
						payload: { msg: "Producto Creado con Éxito", color: "success" },
					});
					resolve(true);
					pid = resp.data.data.id;
				})
				.catch((err) => {
					dispatch({
						type: PRODUCTO_FEEDBACK,
						payload: { msg: "error no se pudo crear el producto", color: "danger" },
					});
					reject(false);
				});
		})
			.then(() => {
				return new Promise((resolve, reject) => {
					try {
						crearImagen(pid, imagen);
						resolve(true);
					} catch {
						reject(false);
					}
				});
			})
			.then(() => {
				return true;
			});
	};

	//////////////////////////////////////////////////////
	// Editar Producto
	const editarProducto = async (
		id,
		nombre,
		categoria_id,
		precio,
		descripcion,
		imagen
	) => {
		let accessToken = await getaccessToken();
		setLoading();
		const body = {
			nombre: nombre,
			descripcion: descripcion,
			precio: precio,
			categoria_id: categoria_id,
		};
		const presp = await axios
			.patch(`/api/productos/${id}`, body, {
				headers: {
					"Content-Type": "application/json",
					Authorization: `${accessToken}`,
				},
			})
			.catch((err) => {
				return {
					data: { success: false, messages: ["Error en la llamada al servidor"] },
				};
			});
		if (imagen !== null) {
			await editarImagen(id);
			await crearImagen(id, imagen);
		}
		unsetLoading();
		return presp.data;
	};

	////////////////////////////////////////////////////
	// Funcion para crear imagen
	const crearImagen = async (pid, img) => {
		let accessToken = await getaccessToken();
		return new Promise((resolve, reject) => {
			const fd = new FormData();
			fd.append("imagen", img, img.name);
			axios
				.post(`/api/imagenes/${pid}`, fd, {
					headers: {
						"Content-Type": "application/json",
						Authorization: `${accessToken}`,
					},
				})
				.then((resp) => {
					resolve(true);
				})
				.catch((err) => {
					dispatch({
						type: PRODUCTO_FEEDBACK,
						payload: { msg: "error", color: "danger" },
					});
					reject(false);
				});
		});
	};

	//////////////////////////////////////////////////
	//edición de imagen
	const editarImagen = async (pid) => {
		let accessToken = await getaccessToken();
		const fakebod = { fake: "body" };
		return new Promise((resolve, reject) => {
			axios
				.patch(`/api/imagenes/${pid}`, fakebod, {
					headers: {
						"Content-Type": "application/json",
						Authorization: `${accessToken}`,
					},
				})
				.then((resp) => {
					resolve(resp.data);
				})
				.catch((err) => {
					reject(err);
					console.log(err.request);
				});
		});
	};

	//////////////////////////////////////////////////
	//se define funcion para traer todos los productos
	const traerProductos = () => {
		return new Promise((resolve, reject) => {
			setLoading();
			axios
				.get("api/productos")
				.then((resp) => {
					if (typeof resp.data.data.productos !== "undefined") {
						dispatch({ type: PRODUCTOS, payload: resp.data.data.productos });
					} else {
						dispatch({
							type: PRODUCTO_FEEDBACK,
							payload: { msg: "error", color: "danger" },
						});
					}
					resolve(true);
				})
				.catch((error) => {
					dispatch({ type: PRODUCTO_FEEDBACK, payload: error.response.data });
					reject(false);
				});
		});
	};

	//////////////////////////////////////////////////
	//se define funcion para traer todos los productos
	const traerProductosDisp = async () => {
		var feed = false;
		const resp = await axios.get("api/productosdisp/1");
		if (resp.data.success && typeof resp.data.data.productos !== "undefined") {
			console.log("entra 2");
			dispatch({ type: DISPONIBLES, payload: resp.data.data.productos });
			feed = true;
		}
		return feed;
	};

	//////////////////////////////////////////////////
	//se define funcion para traer todos los productos
	const traerProductosByCat = async (cat) => {
		setLoading();
		var feedback = {};
		try {
			const resp = await axios.get(`api/productos/categoria/${cat}`);
			if (resp) {
				dispatch({ type: DISPONIBLES, payload: resp.data.data.productos });
				feedback.msg =
					"Se encontraron " +
					resp.data.data.numeroDeProductos +
					" productos en esta categoría";
				feedback.status = "success";
				return feedback;
			}
		} catch (error) {
			feedback.msg = "No se encontraron productos en esa categoría";
			feedback.status = "danger";
			traerProductosDisp();
			return feedback;
		}
	};

	/////////////////////////////////////////////////
	// Filtrar productos por categoría
	const filtrarByCat = (cat) => {
		if (state.productos.length !== 0) {
			if (cat !== 0) {
				const filtrados = state.productos.filter((row) => row.categoria_id === cat);
				dispatch({ type: PRODUCTOS, payload: filtrados });
				if (filtrados.length === 0) {
					return "La categoría no tiene productos";
				}
			} else {
				limpiarProductos();
				return "todos los productos";
			}
		}
	};
	/////////////////////////////////////////////////
	// Filtrar productos por categoría
	const filtrarDispByCat = (cat) => {
		if (state.disponibles.length !== 0) {
			if (cat !== 0) {
				const filtrados = state.disponibles.filter(
					(row) => row.categoria_id === cat
				);
				dispatch({ type: DISPONIBLES, payload: filtrados });
				if (filtrados.length === 0) {
					return "La categoría no tiene productos";
				}
			} else {
				limpiarProductosDisp();
				return "todos los productos";
			}
		}
	};
	///////////////////////
	//Buscar productos
	const buscarProductos = (tipo, textoRequerido) => {
		return new Promise((resolve, reject) => {
			let resp = null;
			setLoading();
			axios
				.get(`api/productos/busqueda/${tipo}/${textoRequerido}`)
				.then((response) => {
					if (response.data.success) {
						tipo === 1
							? dispatch({ type: BUSQUEDA, payload: response.data.data.productos })
							: dispatch({ type: BUSQDISP, payload: response.data.data.productos });
						resp = {
							mensaje:
								"Se encontraron " + response.data.data.nuemroDeProductos + " productos",
							tipo: "success",
						};
						resolve(resp);
					} else {
						resp = {
							mensaje: "No Se encontraron productos",
							tipo: "success",
						};
						reject(resp);
					}
				})
				.catch((error) => {
					resp = {
						mensaje: "No Se encontraron productos, ocurrio un error: " + error,
						tipo: "success",
					};
					reject(resp);
				});
		});
	};

	////////////////////////
	// traer catergorias
	const traerCategorias = async () => {
		await axios
			.get("/api/categorias")
			.then((response) => {
				if (typeof response.data.success !== "undefined") {
					dispatch({ type: CATEGORIAS, payload: response.data.data.categorias });
				}
			})
			.catch((error) => {
				dispatch({ type: PRODUCTO_FEEDBACK, payload: error.response.data });
			});
	};

	////////////////////////////
	//borrar producto
	const borrarProducto = async (pid) => {
		let accessToken = await getaccessToken();
		return new Promise((resolve, reject) => {
			let respuesta = null;
			axios
				.delete(`/api/productos/${pid}`, {
					headers: {
						Authorization: `${accessToken}`,
					},
				})
				.then((resp) => {
					respuesta = {
						msg: "producto eliminado",
						tipo: "success",
						resp: resp.data.messages,
					};
					resolve(respuesta);
				})
				.catch((error) => {
					respuesta = {
						msg: "Error: " + error.response.data.messages[0],
						tipo: "danger",
						resp: error,
					};
					reject(respuesta);
				});
		});
	};

	//Limpiar productos
	const limpiarProductos = () => {
		dispatch({ type: LIMPIAR_PRODUCTOS });
		traerProductos();
	};
	//Limpiar productos
	const limpiarProductosDisp = () => {
		dispatch({ type: LIMPIAR_PRODUCTOSDISP });
		traerProductosDisp();
	};

	//funcion para traer detalles de producto
	const traerProducto = async (id) => {
		setLoading();
		const resp = await axios.get(`/api/productos/${id}`);
		dispatch({ type: PRODUCTO, payload: resp.data.data.Producto });
	};

	//limpiar mensaje
	const limpiarMensaje = () => {
		dispatch({ type: LIMPIAR_PRODUCTO_FEEDBACK });
	};

	//set loading
	const setLoading = () => dispatch({ type: SET_LOADING });
	//unset loading
	const unsetLoading = () => dispatch({ type: UNSET_LOADING });

	return (
		<ProdContext.Provider
			value={{
				productos: state.productos,
				loading: state.loading,
				producto: state.producto,
				busqueda: state.busqueda,
				productoFeed: state.productoFeed,
				categorias: state.categorias,
				disponibles: state.disponibles,
				limpiarMensaje,
				setLoading,
				traerProductos,
				traerProducto,
				buscarProductos,
				limpiarProductos,
				traerCategorias,
				crearProducto,
				crearImagen,
				borrarProducto,
				editarImagen,
				editarProducto,
				traerProductosDisp,
				traerProductosByCat,
				filtrarByCat,
				filtrarDispByCat,
			}}
		>
			{props.children}
		</ProdContext.Provider>
	);
};

export default ProdState;
