import React from "react";
import PropTypes from "prop-types";
const Tdinventario = ({
	inv: { producto_id, thumb, modelo, cantidad, loc },
}) => {
	let locacion = "";
	switch (loc) {
		case 2:
			locacion = "en Salida SLP";
			break;
		case 3:
			locacion = "en Centro";
			break;
		case 4:
			locacion = "en Arriaga";
			break;
		case 5:
			locacion = "en Bodega 1";
			break;
		case 6:
			locacion = "en Bodega 2";
			break;
		case 7:
			locacion = "en Bodega 3";
			break;
	}

	return (
		<div className='sgrid-5 invItem' id={producto_id}>
			<div className='invimgContainer'>
				<img src={thumb} alt='' />
			</div>
			<div className='gspan-3 gridCenter'>
				<p className='invPnombre'>{modelo}</p>
				<p className='invPcat'>{locacion}</p>
			</div>
			<div className='inventario gridCenter'>
				<p className={`${cantidad < 6 ? "danger" : null} invP`}>{cantidad}</p>
			</div>
		</div>
	);
};
Tdinventario.propTypes = {
	inv: PropTypes.object.isRequired,
};
export default Tdinventario;
