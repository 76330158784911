import React, { useContext, useState, useEffect } from "react";
import AlertContext from "../../contexto/alerta/alertContext";
import CuentaContext from "../../contexto/cuentas/cuentaContext";
const Thgastos = (props) => {
	const cuentaContext = useContext(CuentaContext);
	const alertContext = useContext(AlertContext);
	const { cancelarGasto } = cuentaContext;
	const { setAlert } = alertContext;
	const datos = props.datos;
	const sucur = props.suc;
	const [datosF, setDatosf] = useState(datos);
	const fecha = props.fecha;
	const refresh = props.refresh;
	const borrarGasto = async (id) => {
		let resp = null;
		let sec = window.confirm("Desea cancelar el gasto?");
		if (sec) {
			resp = await cancelarGasto(id);
			if (resp.data.success) {
				setAlert(resp.data.messages, "success");
				refresh(fecha, fecha);
			} else {
				setAlert("algo malio sal", "danger");
			}
		}
	};
	const sucurSwitch = (suc) => {
		if (suc === "todos" || suc === "s") {
			setDatosf(datos);
		} else {
			const gastoFiltrados = datos.filter((row) => row.sucursal === suc);
			setDatosf(gastoFiltrados);
		}
	};
	useEffect(() => {
		sucurSwitch(sucur);
	}, [datos, sucur]);
	return (
		<>
			<h3>Gastos Hoy: {fecha}</h3>
			<table className='w-100'>
				<thead>
					<tr>
						<th>Monto</th>
						<th>Motivo</th>
						<th>Usuario</th>
						<th>Sucursal</th>
						<th>Cnl</th>
					</tr>
				</thead>
				<tbody>
					{datosF.map((row, i) => {
						return (
							<tr key={i}>
								<td className={row.status === 0 ? "cancelada" : null}>{row.monto}</td>
								<td className={row.status === 0 ? "cancelada" : null}>{row.motivo}</td>
								<td className={row.status === 0 ? "cancelada" : null}>{row.usuario}</td>
								<td className={row.status === 0 ? "cancelada" : null}>
									{row.sucursal}
								</td>
								<td>
									<i
										onClick={() => borrarGasto(row.id)}
										className={row.status == 1 ? "fas fa-trash-alt lead" : null}
									></i>
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		</>
	);
};

export default Thgastos;
