import React, { useState } from "react";
import Thproductos from "../productos/Thproductos.js";
import Busqueda from "../productos/Busqueda.js";
import Newproducto from "../productos/Newproducto.js";

const Adprod = () => {
	const [newProd, setNewProd] = useState(false);
	const newPrdToggle = () => {
		newProd ? setNewProd(false) : setNewProd(true);
	};

	return (
		<div id='AdminProd' className='AdminProd'>
			<h2 className='my'>
				Productos<i className='fas fa-plus-square' onClick={newPrdToggle}></i>
			</h2>
			{newProd ? <Newproducto /> : null}
			<Busqueda />
			<Thproductos />
		</div>
	);
};

export default Adprod;
