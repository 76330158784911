import React, { useContext, useEffect, useState } from "react";
import InvContext from "../../contexto/inventario/invContext.js";
import Spinner from "../estructura/Spinner.js";
import Tdinventario from "./Tdinventario.js";
const Thinventario = () => {
	const invContext = useContext(InvContext);
	const { traerInventario, inventario, invLoading } = invContext;
	const inventariolength = Object.keys(inventario).length;
	const [filtrado, setFiltrado] = useState([]);
	const [unfilteredRendered, setUnfilteredRendered] = useState(false);
	const [search, setSearch] = useState("");
	const [loc, setLoc] = useState(0);
	const [lock, setLock] = useState(false);
	const inventarioFiltrado = async (loc) => {
		setFiltrado([]);
		let temp = [];
		if (inventariolength === 0) {
			await traerInventario();
			if (!unfilteredRendered) {
				setFiltrado(inventario);
				setUnfilteredRendered(true);
			}
		}
		setLoc(loc);

		if (loc !== 0) {
			temp = inventario.filter((row) => row.loc === loc);
			setLock(true);
		} else {
			temp = inventario;
			setLock(false);
		}

		setFiltrado(temp);
	};
	const searchChange = (e) => {
		e.preventDefault();
		setSearch(e.target.value);
		if (inventario.length !== 0) {
			let temp2 = filtrado.filter((row) =>
				row.modelo.toLowerCase().includes(e.target.value.toLowerCase())
			);
			if (temp2.length > 0) {
				setFiltrado(temp2);
			} else {
				setFiltrado(inventario);
				setLoc(0);
			}
		}
	};

	const onchageCat = (e) => {
		let cat = e.target.value;
		setFiltrado(inventario);
		let temp = [];
		if (cat > 0 && cat < 15) {
			temp = filtrado.filter((row) => row.categoria == cat);
			setFiltrado(temp);
		}
	};
	console.log(filtrado);

	/////////////////////////////////
	//boton
	const clearear = () => {
		setSearch("");
		inventarioFiltrado(0);
		setFiltrado(inventario);
	};
	useEffect(() => {
		if (inventario.length === 0) {
			traerInventario().then(() => {
				if (!unfilteredRendered) {
					setFiltrado(inventario);
					setUnfilteredRendered(true);
				}
			});
		} else {
			if (!unfilteredRendered) {
				setFiltrado(inventario);
				setUnfilteredRendered(true);
			}
		}
		console.log(loc);
	}, [filtrado, loc]);

	if (inventario.length == 0) {
		return <Spinner />;
	} else {
		return (
			<div>
				<div style={{ display: "flex", alignItems: "center" }}>
					<input
						type='text'
						className='w-100'
						placeholder='Filtrar por nombre'
						onChange={searchChange}
						value={search}
					/>
					<button className='btn btn-dark' onClick={clearear}>
						Limpiar
					</button>
				</div>

				<div className='flex-linea my-1'>
					<button
						className={`btn ${loc == 2 ? "btn-success" : "btn-dark"}`}
						onClick={() => inventarioFiltrado(2)}
					>
						Matriz
					</button>
					<button
						className={`btn ${loc == 3 ? "btn-success" : "btn-dark"}`}
						onClick={() => inventarioFiltrado(3)}
					>
						Centro
					</button>
					<button
						className={`btn ${loc == 4 ? "btn-success" : "btn-dark"}`}
						onClick={() => inventarioFiltrado(4)}
					>
						Arriaga
					</button>
					<button
						className={`btn ${loc == 5 ? "btn-success" : "btn-dark"}`}
						onClick={() => inventarioFiltrado(5)}
					>
						Bodega1
					</button>
					<button
						className={`btn ${loc == 6 ? "btn-success" : "btn-dark"}`}
						onClick={() => inventarioFiltrado(6)}
					>
						Bodega2
					</button>
					<button
						className={`btn ${loc == 7 ? "btn-success" : "btn-dark"}`}
						onClick={() => inventarioFiltrado(7)}
					>
						Bodega3
					</button>
					<button
						className={`btn ${loc == 0 && !lock ? "btn-success" : "btn-dark"}`}
						onClick={() => inventarioFiltrado(0)}
					>
						Todo
					</button>
					<select style={{ width: "100px" }} name='' id='' onChange={onchageCat}>
						<option value='0'>Cats</option>
						<option value='1'>Salas</option>
						<option value='2'>Recamaras</option>
						<option value='3'>Colchones</option>
						<option value='4'>Roperos</option>
						<option value='5'>Comedores</option>
						<option value='6'>Cocinas</option>
						<option value='7'>Alacenas</option>
						<option value='8'>Libreros</option>
						<option value='9'>Electrodoms</option>
						<option value='10'>Electrónica</option>
						<option value='11'>Motos</option>
						<option value='13 Blanca'>Linea Blanca</option>
						<option value='12'>otros</option>
						<option value='20'>Todos</option>
					</select>
				</div>
				<div className='grid-3 nogap'>
					{filtrado.map((inv) => (
						<Tdinventario key={inv.producto_id + inv.loc} inv={inv} />
					))}
					{filtrado.length === 0 && "No hay Inventario en esta sucursal"}
				</div>
			</div>
		);
	}
};
export default Thinventario;
