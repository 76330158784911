import React, { useContext, useEffect, useState, useRef } from "react";
import CuentaContext from "../../contexto/cuentas/cuentaContext.js";
import AlertContext from "../../contexto/alerta/alertContext.js";
import logo from "../assets/logoNota.png";
import Spinner from "../estructura/Spinner.js";

const CuentaSingle = React.forwardRef((props, ref) => {
	const cuentaContext = useContext(CuentaContext);
	const alertContext = useContext(AlertContext);
	const { setAlert } = alertContext;

	/////////////////////////////////////////////////
	/// num to letras
	function Unidades(num) {
		switch (num) {
			case 1:
				return "un";
			case 2:
				return "dos";
			case 3:
				return "tres";
			case 4:
				return "cuatro";
			case 5:
				return "cinco";
			case 6:
				return "seis";
			case 7:
				return "siete";
			case 8:
				return "ocho";
			case 9:
				return "nueve";
		}

		return "";
	} //Unidades()

	function Decenas(num) {
		var decena = Math.floor(num / 10);
		var unidad = num - decena * 10;

		switch (decena) {
			case 1:
				switch (unidad) {
					case 0:
						return "diez";
					case 1:
						return "once";
					case 2:
						return "doce";
					case 3:
						return "trece";
					case 4:
						return "catorce";
					case 5:
						return "quince";
					default:
						return "dieci" + Unidades(unidad);
				}
			case 2:
				switch (unidad) {
					case 0:
						return "veinte";
					default:
						return "venti" + Unidades(unidad);
				}
			case 3:
				return DecenasY("treinta", unidad);
			case 4:
				return DecenasY("cuarenta", unidad);
			case 5:
				return DecenasY("cincuenta", unidad);
			case 6:
				return DecenasY("sesenta", unidad);
			case 7:
				return DecenasY("setenta", unidad);
			case 8:
				return DecenasY("ochenta", unidad);
			case 9:
				return DecenasY("noventa", unidad);
			case 0:
				return Unidades(unidad);
		}
	} //Unidades()

	function DecenasY(strSin, numUnidades) {
		if (numUnidades > 0) return strSin + " y " + Unidades(numUnidades);

		return strSin;
	} //DecenasY()

	function Centenas(num) {
		var centenas = Math.floor(num / 100);
		var decenas = num - centenas * 100;

		switch (centenas) {
			case 1:
				if (decenas > 0) return "ciento " + Decenas(decenas);
				return "cien";
			case 2:
				return "doscientos " + Decenas(decenas);
			case 3:
				return "trescientos " + Decenas(decenas);
			case 4:
				return "cuatrocientos " + Decenas(decenas);
			case 5:
				return "quinientos " + Decenas(decenas);
			case 6:
				return "seicientos " + Decenas(decenas);
			case 7:
				return "setecientos " + Decenas(decenas);
			case 8:
				return "ochocientos " + Decenas(decenas);
			case 9:
				return "novecientos " + Decenas(decenas);
		}

		return Decenas(decenas);
	} //Centenas()

	function Seccion(num, divisor, strSingular, strPlural) {
		var cientos = Math.floor(num / divisor);
		var resto = num - cientos * divisor;

		var letras = "";

		if (cientos > 0)
			if (cientos > 1) letras = Centenas(cientos) + " " + strPlural;
			else letras = strSingular;

		if (resto > 0) letras += "";

		return letras;
	} //Seccion()

	function Miles(num) {
		var divisor = 1000;
		var cientos = Math.floor(num / divisor);
		var resto = num - cientos * divisor;

		var strMiles = Seccion(num, divisor, "un mil", "mil");
		var strCentenas = Centenas(resto);

		if (strMiles == "") return strCentenas;

		return strMiles + " " + strCentenas;
	} //Miles()

	function Millones(num) {
		var divisor = 1000000;
		var cientos = Math.floor(num / divisor);
		var resto = num - cientos * divisor;

		var strMillones = Seccion(num, divisor, "un millon de", "millones de");
		var strMiles = Miles(resto);

		if (strMillones == "") return strMiles;

		return strMillones + " " + strMiles;
	} //Millones()

	function NumeroALetras(num) {
		var data = {
			numero: num,
			enteros: Math.floor(num),
			centavos: Math.round(num * 100) - Math.floor(num) * 100,
			letrasCentavos: "",
			letrasMonedaPlural: "Pesos", //"PESOS", 'Dólares', 'Bolívares', 'etcs'
			letrasMonedaSingular: "Peso", //"PESO", 'Dólar', 'Bolivar', 'etc'

			letrasMonedaCentavoPlural: "centavos",
			letrasMonedaCentavoSingular: "centavos",
		};

		if (data.centavos > 0) {
			data.letrasCentavos =
				"con " +
				(function () {
					if (data.centavos == 1)
						return Millones(data.centavos) + " " + data.letrasMonedaCentavoSingular;
					else return Millones(data.centavos) + " " + data.letrasMonedaCentavoPlural;
				})();
		}

		if (data.enteros == 0)
			return "cero " + data.letrasMonedaPlural + " " + data.letrasCentavos;
		if (data.enteros == 1)
			return (
				Millones(data.enteros) +
				" " +
				data.letrasMonedaSingular +
				" " +
				data.letrasCentavos
			);
		else
			return (
				Millones(data.enteros) +
				" " +
				data.letrasMonedaPlural +
				" " +
				data.letrasCentavos
			);
	} //NumeroALetras()
	//////////////////////////////////////////////////

	const {
		unsetCuenta,
		cuenta,
		crearAbono,
		traerAbonosByNota,
		abonosr,
		statusdCuenta,
		traerCuentas,
		traerCuentasPagadas,
	} = cuentaContext;
	const {
		folio,
		cliente,
		fecha,
		monto,
		tel,
		refe,
		mpago,
		dir,
		conceptos,
		status,
		ciudad,
		abonos,
		sucursal,
		vendedor,
	} = cuenta;
	console.log(cuenta);
	console.log(abonosr);
	let abo = 0;
	let enganche = 0;
	if (abonosr.length > 0) {
		abonosr.forEach((row) => {
			abo = abo + parseFloat(row["monto"]);
		});
		enganche = abonosr[0]["monto"];
	}

	const [abono, setAbono] = useState(0);
	const [tipo, setTipo] = useState("Efectivo");

	const onChangeAbono = (e) => setAbono(e.target.value);
	let estatus =
		status === "pendiete"
			? "bg-danger"
			: status === "pagada"
			? "bg-success"
			: "bg-light";
	let ffecha = "";
	if (typeof fecha !== "undefined") {
		const dateRaw = fecha.substring(0, 10);
		const fechaArr = dateRaw.split("-");
		const meses = [
			"dud",
			"Enero",
			"Feb",
			"Mar",
			"Abril",
			"Mayo",
			"Jun",
			"Jul",
			"Ago",
			"Sep",
			"Oct",
			"Nov",
			"Dic",
		];
		ffecha =
			fechaArr[2] +
			"/" +
			meses[fechaArr[1].replace(/^0+/, "")] +
			"/" +
			fechaArr[0];
	}
	let conceptosArr = [];
	if (typeof conceptos !== "undefined") {
		conceptosArr = conceptos;
	}

	const onChangeTipo = (e) => {
		console.log(e.target.value);
		setTipo(e.target.value);
	};

	const pushAbono = async () => {
		if ((typeof folio !== "undefined" || folio !== null) && abono !== 0) {
			if (abono <= monto - abo) {
				const resp = await crearAbono(folio, abono, tipo);
				console.log(resp);
				if (resp.success) {
					setAbono(0);
					setAlert("Abono agregado con éxito", "success");
					traerAbonosByNota(folio);
				}
			} else {
				alert("no puede realizar el abono por que es mayor que el saldo");
			}
		} else {
			setAlert(
				"No se puede ingresar el abono seleccione una nota o agregue un monto",
				"danger"
			);
		}
	};
	const liquidar = async () => {
		if (monto - abo === 0) {
			let body = {
				cuentaid: folio,
				status: "pagada",
			};
			const resp = await statusdCuenta(body);
			console.log(resp);
			traerCuentasPagadas();
			alert("liquidada");
		} else {
			alert("no puede liquidar la nota mientras tenga saldo pendiente");
		}
	};
	const entregada = async () => {
		alert("entra");
		if (monto - abo === 0) {
			let body = {
				cuentaid: folio,
				status: "entregada",
			};
			const resp = await statusdCuenta(body);
			console.log(resp);
			traerCuentasPagadas();
			alert("Estatus cambiado a entregada");
		} else {
			alert(
				"no puede marcar como entregada mientras la nota tenga saldo pendiente"
			);
		}
	};
	useEffect(() => {
		if (typeof folio !== "undefined" && abonosr.length === 0) {
			//traerAbonosByNota(folio);
		}
	}, [cuenta, abonosr]);

	const preletras = NumeroALetras(monto);
	var letras = preletras.charAt(0).toUpperCase() + preletras.slice(1);
	const presaldo = NumeroALetras(monto - abo);
	var saldoletras = presaldo.charAt(0).toUpperCase() + presaldo.slice(1);

	return (
		<div>
			<h2>Detalles de Nota</h2>
			<div ref={ref} className='p-1'>
				<table
					className='nota w-100'
					style={{ boxShadow: "#aaa 5px 5px 10px", tableLayout: "fixed" }}
				>
					<thead>
						<tr>
							<td
								colSpan={4}
								style={{ padding: "0", background: "lightgrey", borderRight: "none" }}
							>
								<img
									src={logo}
									alt=''
									style={{ height: "90px", width: "auto", margin: "3px" }}
								/>
							</td>
							<td
								colSpan={6}
								style={{ padding: "0", background: "lightgrey", borderLeft: "none" }}
							>
								<h2 className='lead' style={{ margin: "0" }}>
									Mueblerias las 3mmm
								</h2>
								<p className='small'>
									Carr. Salida S.L.P., Ojuelos de Jalisco, jal. Teléfono: (496) 6881663
								</p>
							</td>
						</tr>
						<tr>
							<td colSpan={10}>
								<p className='small m0'>A nombre de:</p>
								<h3 className='m0'>{cliente}</h3>
							</td>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td colSpan={6} className='nota'>
								{" "}
								<span
									className='small notahead'
									style={{ position: "absolute", top: "3px", left: "3px" }}
								>
									Fecha:
								</span>
								{ffecha}
							</td>
							<td colSpan={4} className='nota'>
								<span
									className='small  notahead'
									style={{ position: "absolute", top: "3px", left: "3px" }}
								>
									Folio:
								</span>
								{folio}
							</td>
						</tr>
						<tr>
							<td colSpan={6} className='nota'>
								<span className='small notahead'>Dirección: </span>
								{dir}
							</td>
							<td colSpan={4} className='nota'>
								<span className='small notahead'>Ciudad: </span>
								{ciudad}
							</td>
						</tr>
						<tr>
							<td colSpan={6} className='nota'>
								<span className=' small notahead'>Tel: </span>
								{tel}
							</td>
							<td colSpan={4} className={"nota " + estatus}>
								<span className=' small notahead'>Estatus:</span>
								{status}
							</td>
						</tr>
						<tr>
							<td colSpan={6} className='nota'>
								<span className=' small notahead'>Vendedor: </span>
								{vendedor}
							</td>
							<td colSpan={4} className='nota'>
								<span className='small notahead'>Sucursal: </span>
								{sucursal}
							</td>
						</tr>
						<tr>
							<td colSpan={6} className='nota'>
								<span className='notahead small'>Referencia: </span>
								{refe}
							</td>
							<td colSpan={4} className='nota'>
								<span className='small notahead'>Pago: </span>
								{mpago}
							</td>
						</tr>
						<tr>
							<th>Cant.</th>
							<th colSpan={5}>Concepto</th>
							<th colSpan={2}>Precio</th>
							<th colSpan={2}>SubT</th>
						</tr>
						{conceptosArr.map((fila, i) => (
							<tr key={i}>
								<td className='nota'>{fila.cantidad}</td>
								<td colSpan={5} className='nota'>
									{fila.concepto}
								</td>
								<td colSpan={2} className='nota'>
									${fila.precio}
								</td>
								<td colSpan={2} className='nota'>
									${fila.subtotal}
								</td>
							</tr>
						))}
						<tr>
							<td colSpan={10}>
								<strong className='lead'>Total: ${monto}</strong>
							</td>
						</tr>
						<tr>
							<td colSpan={10}>
								<strong className=''>Enganche: ${enganche}</strong>
							</td>
						</tr>
						<tr>
							<td colSpan={10}>
								<p>{letras}</p>
							</td>
						</tr>
						<tr>
							<td colSpan={10}>
								<p className='small text-dark'>
									Conserve su ticket para cualquier duda, aclaración y ó Garantía.
								</p>
							</td>
						</tr>
					</tbody>
				</table>
				<div className='grid-2 noprint my-1'>
					<button className='btn btn-block btn-dark m0 noprint' onClick={liquidar}>
						Liquidar
					</button>
					<button className='btn btn-block btn-dark m0 noprint' onClick={entregada}>
						Entregada
					</button>
				</div>
			</div>
		</div>
	);
});

export default CuentaSingle;
