import React, { useContext } from "react";
import { useHistory } from "react-router";
import PropTypes from "prop-types";
import UserContext from "../../contexto/usuario/userContext.js";
import AutContext from "../../contexto/autentificacion/autContext.js";
import AlertContext from "../../contexto/alerta/alertContext.js";

const Tdusuarios = ({ usr: { id, fullname, username, role } }) => {
	const userContext = useContext(UserContext);
	const autContext = useContext(AutContext);
	const alertContext = useContext(AlertContext);
	const history = useHistory();

	const { borrarUsuario } = userContext;
	const { refreshSession } = autContext;
	const { setAlert } = alertContext;

	//////////////////////////////////
	// borrar producto
	const borrarUsr = async (e) => {
		e.stopPropagation();
		await refreshSession();
		let delconfirm = window.confirm("El Usuario será eliminado, está seguro?");
		if (delconfirm) {
			try {
				const resp = borrarUsuario(id);
				setAlert(resp.mensaje, resp.color);
			} catch (cresp) {
				setAlert(cresp.msg, cresp.tipo);
			}
		}
	};

	return (
		<tr
			id={id}
			onClick={() => {
				history.push(`/usuarios/${id}`);
			}}
			className='tdlink'
		>
			<td>{username}</td>
			<td>{fullname}</td>
			<td>{role}</td>
			<td>
				<i onClick={borrarUsr} className='fas fa-trash-alt lead'></i>
			</td>
		</tr>
	);
};
Tdusuarios.propTypes = {
	usr: PropTypes.object.isRequired,
};
export default Tdusuarios;
