import { CUENTAS, CUENTA, SET_LOADING, ABONOS, GASTOS } from "../types.js";
const invReducer = (state, action) => {
	switch (action.type) {
		case CUENTAS:
			return {
				...state,
				cuentas: action.payload,
				cuentasLoading: false,
			};
		case CUENTA:
			return {
				...state,
				cuenta: action.payload,
			};
		case SET_LOADING:
			return {
				...state,
				cuentasLoading: true,
			};
		case ABONOS:
			return {
				...state,
				abonosr: action.payload,
			};
		case GASTOS:
			return {
				...state,
				gastos: action.payload,
			};
		default:
			return state;
	}
};
export default invReducer;
