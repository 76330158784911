import React, { useEffect, useContext, useState } from "react";
import InvContext from "../../contexto/inventario/invContext.js";
import Tdmovimientos from "./Tdmovimientos.js";
import Spinner from "../estructura/Spinner.js";

const Thmovimientos = () => {
	const invContext = useContext(InvContext);
	const { movimientos, traerMovimientos, movLoading } = invContext;
	const movimientosLenght = Object.keys(movimientos).length;
	const [filtrados, setFiltrados] = useState([]);
	const [loadcheck, setLoadcheck] = useState(false);

	const filtBLoc = (loc) => {
		let temp = movimientos.filter((row) => row.designacion === loc);
		temp.length > 0 && setFiltrados(temp);
		loc === "todo" && setFiltrados(movimientos);
	};
	console.log(movimientos);

	useEffect(() => {
		if (movimientosLenght === 0 && !loadcheck) {
			traerMovimientos();
			console.log(movimientos);
		} else {
			setFiltrados(movimientos);
			setLoadcheck(true);
		}
		//eslint-disable-next-line
	}, []);

	if (movLoading) {
		return <Spinner />;
	} else {
		return (
			<>
				<h2>Movimientos de inventario</h2>
				<p>Filtrar por sucursal</p>
				<div className='flex'>
					<button
						className='btn btn-light'
						onClick={() => {
							filtBLoc("Centro");
						}}
					>
						Centro
					</button>
					<button
						className='btn btn-light'
						onClick={() => {
							filtBLoc("Matriz");
						}}
					>
						Salida
					</button>
					<button
						className='btn btn-light'
						onClick={() => {
							filtBLoc("Arriaga");
						}}
					>
						Arriaga
					</button>
					<button
						className='btn btn-light'
						onClick={() => {
							filtBLoc("Bodega1");
						}}
					>
						Bodega1
					</button>
					<button
						className='btn btn-light'
						onClick={() => {
							filtBLoc("Bodega2");
						}}
					>
						Bodega2
					</button>
					<button
						className='btn btn-light'
						onClick={() => {
							filtBLoc("Bodega3");
						}}
					>
						Bodega3
					</button>
					<button
						className='btn btn-light'
						onClick={() => {
							filtBLoc("todo");
						}}
					>
						Todos
					</button>
				</div>
				<div className='frame my-1'>
					<ul>
						{filtrados.map((mov) => (
							<Tdmovimientos key={mov.id} mov={mov} />
						))}
					</ul>
				</div>
			</>
		);
	}
};

export default Thmovimientos;
