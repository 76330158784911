import { CLIENTES, CLIENTE, SET_LOADING } from "../types.js";
const userReducer = (state, action) => {
	switch (action.type) {
		case SET_LOADING:
			return {
				...state,
				clienteLoading: true,
			};
		case CLIENTES:
			return {
				...state,
				clientes: action.payload,
				clienteLoading: false,
			};
		case CLIENTE:
			return {
				...state,
				cliente: action.payload,
				clienteLoading: false,
			};
		default:
			return state;
	}
};
export default userReducer;
