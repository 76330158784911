import React, { useEffect, useContext, useState } from "react";
import ProdContext from "../../contexto/producto/prodContext.js";
import AutContext from "../../contexto/autentificacion/autContext.js";
import AlertContext from "../../contexto/alerta/alertContext.js";
import Spinner from "../estructura/Spinner.js";

const Patchproducto = (props) => {
	const prodContext = useContext(ProdContext);
	const autContext = useContext(AutContext);
	const alertContext = useContext(AlertContext);
	const {
		categorias,
		traerCategorias,
		traerProducto,
		producto,
		editarProducto,
		loading,
	} = prodContext;
	const { refreshSession } = autContext;
	const { setAlert } = alertContext;

	////////////////////////////////////////////
	// state
	const [nombre, setNombre] = useState("");
	const [categoria_id, setCat] = useState(producto.Categoria_id);
	const [precio, setPrecio] = useState("");
	const [descripcion, setDescription] = useState("");
	const [imagen, setImagen] = useState(null);

	///////////////////////////////////////////
	//onchange de imputs
	const onChangeNombre = (e) => setNombre(e.target.value);
	const onChangeCat = (e) => setCat(e.target.value);
	const onChangePrecio = (e) => setPrecio(e.target.value);
	const onChangeDescription = (e) => setDescription(e.target.value);
	const onChangeImagen = (e) => setImagen(e.target.files[0]);

	//////////////////////////////////////////
	// Actualizar prodcuto
	const patchProducto = async (e) => {
		e.preventDefault();
		await refreshSession();
		const edit = await editarProducto(
			producto.id,
			nombre,
			categoria_id,
			precio,
			descripcion,
			imagen
		);
		console.log(edit.success);
		traerProducto(producto.id);
		if (edit.success) {
			props.tog();
			setAlert("Producto actualizado!", "success");
		} else {
			setAlert("Producto no actualizado!", "success");
		}
	};

	/////////////////////////////////////////
	// set data original
	useEffect(() => {
		traerCategorias();
		if (producto.length !== 0) {
			setNombre(producto.Nombre);
			setCat(producto.Categoria_id);
			setPrecio(producto.Precio);
			setDescription(producto.Descripcion);
		}
		//eslint-disable-next-line
	}, []);
	if (loading) {
		return <Spinner />;
	} else {
		return (
			<div>
				<form id='npform' onSubmit={patchProducto} className='grid-2 smallgap'>
					<div className='gspan-2'>
						<label htmlFor='nombre'>Nombre del Producto</label>
						<input
							type='text'
							name='nombre'
							placeholder='Ingrese el nombre del producto'
							value={nombre}
							onChange={onChangeNombre}
							min='0'
							max='9999'
							className='bigimput my'
						/>
					</div>
					<div className=''>
						<label htmlFor='categoria_id'>categoría</label>
						<select onChange={onChangeCat} className='my bigimput'>
							<option value={producto.Categoria_id}>{producto.Categoria}</option>
							{categorias.map((cate) => (
								<option key={cate.id} value={cate.id}>
									{cate.Nombre}
								</option>
							))}
						</select>
					</div>
					<div className=''>
						<label htmlFor='precio'>Precio</label>
						<input
							type='number'
							step='0.01'
							name='precio'
							placeholder='Precio'
							value={precio}
							onChange={onChangePrecio}
							min='0'
							max='999999'
							className='my'
						/>
					</div>
					<div className='gspan-2'>
						<label htmlFor='descripcion'>Descripción</label>
						<textarea
							name='descripcion'
							placeholder='Ingrese la descripción del producto'
							value={descripcion}
							onChange={onChangeDescription}
							min='0'
							max='999'
							className='bigimput my'
						/>
					</div>
					<div className='gspan-2'>
						<label htmlFor='image'>Imagen</label>
						<input
							type='file'
							name='imagen'
							placeholder='Ingrese la descripción del producto'
							onChange={onChangeImagen}
							className='btn my'
						/>
					</div>
					<div className='gspan-2'>
						<input
							type='submit'
							className='btn btn-dark btn-block my'
							value='Actualizar Producto'
						/>
					</div>
				</form>
			</div>
		);
	}
};

export default Patchproducto;
