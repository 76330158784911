import React, { useState, useContext } from "react";
//import { useHistory } from 'react-router-dom';
import Spinner from "../estructura/Spinner.js";
import UserContext from "../../contexto/usuario/userContext.js";
import AlertContext from "../../contexto/alerta/alertContext";
import AutContext from "../../contexto/autentificacion/autContext.js";

const Registro = (props) => {
	//////////////////////////////////////
	//state interno para manejar imputs
	const [user, setUser] = useState("");
	const [password, setPassword] = useState("");
	const [nombre, setNombre] = useState("");
	const [mail, setMail] = useState("");
	const [tel, setTel] = useState("");

	//////////////////////////////////////////
	// destructurado de variables y metodos
	const userContext = useContext(UserContext);
	const alertContext = useContext(AlertContext);
	const autContext = useContext(AutContext);
	const { setAlert } = alertContext;
	const { nuevoUsuario, userLoading, traerUsuarios } = userContext;
	const { refreshSession } = autContext;
	const { toggOff } = props;
	//const historia = useHistory();
	//definir si es admin
	let isAdmin = null;
	if (localStorage.getItem("role") === "Admin") {
		isAdmin = true;
	} else {
		isAdmin = false;
	}

	////////////////////////////////////////////////////////////
	// funcion on change captura los imputs y los pasa al state
	const onChange = (e) => {
		switch (e.target.name) {
			case "user":
				setUser(e.target.value);
				break;
			case "password":
				setPassword(e.target.value);
				break;
			case "nombre":
				setNombre(e.target.value);
				break;
			case "mail":
				setMail(e.target.value);
				break;
			case "tel":
				setTel(e.target.value);
				break;
			default:
				setAlert("Hubo un error", "danger");
				break;
		}
	};

	////////////////////////////////////////////////////////////
	//funcion onsubmit maneja el envio del formulario
	const onSubmit = async (e) => {
		e.preventDefault();
		const res1 = await refreshSession();

		if (res1) {
			let fuser = user !== "" ? user : null;
			let fpass = password !== "" ? password : null;
			console.log(fuser);
			const ufeed = await nuevoUsuario(fuser, fpass, nombre, mail, tel);
			console.log(ufeed);
			setAlert(ufeed.mensaje, ufeed.color);
		}
		setUser("");
		setPassword("");
		setNombre("");
		setMail("");
		setTel("");
		await traerUsuarios();
		// aqui redirige a pagina de usuario
		//await pagerefresh();
		//toggOff();
	};

	/////////////////////////////////////////////////////////////
	// salida
	if (userLoading) {
		return <Spinner />;
	} else {
		return (
			<div className='registro page-wrapp'>
				<form className='regForm'>
					{isAdmin ? <h2>Crear Usuario</h2> : <h2>Regístrate!</h2>}
					{isAdmin ? (
						<h3>Ingrese los datos del Usuario</h3>
					) : (
						<h3>Ingresa tus datos para generar una cuenta</h3>
					)}

					<label htmlFor='user'>Nombre de usuario</label>
					<input
						type='text'
						name='user'
						placeholder='Ingrese un nombre de usuario'
						value={user}
						onChange={onChange}
					/>

					<label htmlFor='password'>Contraseña</label>
					<input
						type='password'
						name='password'
						placeholder='Ingrese una contraseña'
						value={password}
						onChange={onChange}
					/>

					<label htmlFor='nombre'>Nombre o razón social</label>
					<input
						type='text'
						name='nombre'
						placeholder='Ingrese Nombre completo'
						value={nombre}
						onChange={onChange}
					/>

					<label htmlFor='mail'>Correo electrónico</label>
					<input
						type='text'
						name='mail'
						placeholder='Ingrese su correo'
						value={mail}
						onChange={onChange}
					/>

					<label htmlFor='tel'>Teléfono</label>
					<input
						type='text'
						name='tel'
						placeholder='Ingrese su Numero telefónico'
						value={tel}
						onChange={onChange}
					/>

					<button type='submit' className='call2A btn-dark btn' onClick={onSubmit}>
						Registrar
					</button>
				</form>
			</div>
		);
	}
};

export default Registro;
